import { useEffect, useState } from "react";
import SearchByDate from "../../global/SearchForms/SearchByDate";
import SearchBox from "../components/referrerGroupManager/SearchBox";
import RightDrawer from "../../../components/HtmlComponents/modals/RightDrawer";
import { formatDate, getPreviousMonth, getCurrentMonth, convertDateintoUnix, getLastDayOfMonth, getStartAndEndDateForGivenPeriod, removeSpace } from '../../../helpers';
import { useToken, useAccessAdvanceReporting } from "../../../hooks/useAuth";
import {
    getUsers, getReferrersList, getReferrerGroupsList, getCreditRepList, loadReporting,
    getReportingBookmark, getReportingBookmarks, createReportingBookmark, deleteReportingBookmark
} from '../../../api';
import moment from "moment";
import LineGraph from "../components/superAdmin/components/LineGraph";
import DrawPieChart from "../components/superAdmin/components/DrawPieChart";
import MultibarGraph from "../components/superAdmin/components/MultibarGraph";
import CreateBookmark from "../components/shared/CreateBookmark";

//getTotalReferralsReporting
const ReferrerGroupManagerReporting = () => {

    const token = useToken();
    const [bookmarks, setBookmarks] = useState([]);
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: {} });
    const [searchFilters, setSearchFilters] = useState({
        reportFor: "",
        reportForExtraInfo: [],
        stat: "",
        statExtraInfo: { subStat: "", pieChart: false },
        periodicType: "",
        period: { name: "", startDate: "", endDate: "" },
        comparePeriod: { name: "", startDate: "", endDate: "" }
    });

    //FETCH REFERRER GROUP PERMISSIONS TO FIND OUT IF THE USER HAS PRIVELLAGE TO ACCESS THE REPORTING
    const accessAdvanceReporting = true; //useAccessAdvanceReporting();

    const generateReport = async (searchFiltersTmp) => {
        setSearchFilters(JSON.parse(JSON.stringify(searchFiltersTmp)));
    }


    //LOAD ALL BOOKMARKS
    const loadBookmarks = async () => {
        const response = await getReportingBookmarks(token);
        if (response["httpCode"] === 200 && response["error"] !== undefined && response["error"] === 0) {
            setBookmarks(response["data"]["bookmarks"]);
        }
    }

    //DELETE BOOKMARK
    const deleteBookmark = async (bookmarkId) => {
        const response = await deleteReportingBookmark(token, bookmarkId);
        if (response["httpCode"] === 200 && response["error"] !== undefined && response["error"] === 0) {
            loadBookmarks();
        }
    }

    useEffect(() => {
        loadBookmarks();    //LAOD BOOKMARKS
    }, [])


    const handler = (name, payload) => {
        switch (name) {
            case "createBookmark":
                setDrawerSettings({ visible: true, heading: "Create Bookmark", childName: "createBookmark", meta: payload });
                break;
            case "closeDrawer":
                setDrawerSettings({ visible: false, heading: "", childName: "", meta: {} });
                break;

            case "getBookmarkReporting":
                //LOOP BOOKMARKS AND GET PAYLAOD FROM BOOKMARKS AGAINST ID PAYLAOD["id"]
                var bookmark = bookmarks.filter(singleBookmark => singleBookmark["id"] === payload["id"]);

                if (bookmark.length > 0) {
                    let bookmarkPaylaod = JSON.parse(bookmark[0]["payload"]);
                    let periodName = bookmarkPaylaod["period"]["name"];
                    let comparePeriodName = bookmarkPaylaod["comparePeriod"]["name"];

                    //IF PERIOD IS NOT CUSTOM PERIOD THEN UPDATE THE START AND END DATE ACCORDING TO THE PERIOD TYPE (LAST6MONTHS ETC)
                    if (periodName !== "userDefinedPeriod") {
                        let periodDateTimeRange = getStartAndEndDateForGivenPeriod(periodName);
                        bookmarkPaylaod["period"]["startDate"] = convertDateintoUnix(periodDateTimeRange["startDate"], "YYYY-MM-DD H:mm:s");
                        bookmarkPaylaod["period"]["endDate"] = convertDateintoUnix(periodDateTimeRange["endDate"], "YYYY-MM-DD H:mm:s");
                    }
                    if (comparePeriodName !== "" && comparePeriodName !== "userDefinedPeriod") {
                        let comparePeriodDateTimeRange = getStartAndEndDateForGivenPeriod(comparePeriodName, comparePeriodName === "samePeriodLastYear" ?
                            {
                                startDate: formatDate(searchFilters["period"]["startDate"], "unix", "YYYY-MM-DD HH:mm:s"),
                                endDate: formatDate(searchFilters["period"]["endDate"], "unix", "YYYY-MM-DD HH:mm:s")
                            } : {});

                        bookmarkPaylaod["comparePeriod"]["startDate"] = convertDateintoUnix(comparePeriodDateTimeRange["startDate"], "YYYY-MM-DD H:mm:s");
                        bookmarkPaylaod["comparePeriod"]["endDate"] = convertDateintoUnix(comparePeriodDateTimeRange["endDate"], "YYYY-MM-DD H:mm:s");
                    }
                    generateReport(bookmarkPaylaod);
                    //generateReport(JSON.parse(bookmark[0]["payload"]));
                }
                break;
        }
    }

    //DRAW TABLE DOES NOT MAKE DIRECT CALLS BUT IT RELIES ON THE DATA PASSED FROM LINE GRAPH SO IT GENERATES TABLE BASED ON THE DATA PASSED

    return (

        <>
            {
                !accessAdvanceReporting ? "": <>
                <div id="kt_content_container" className="container-xxl">
                    <div className="row g-5 g-xl-8 mb-5">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                            <div className="card card-flush">
                                <div className="card-header border-0">
                                    <h3 className="card-title fw-bolder text-dark">Bookmarks</h3>
                                </div>
                                <div className="card-body pt-3 pb-0" style={{ height: "278px", overflowY: "auto", marginBottom: "30px" }}>
                                    <ul className="list-group list-group-flush list-group-active list-group-sm">
                                        {
                                            bookmarks.map(singleBookmark => {
                                                return (
                                                    <li className="list-group-item d-flex align-items-center">
                                                        <div className="d-flex align-items-center justify-content-between mb-3" style={{ width: "100%" }}>
                                                            <div className="d-flex align-items-center">
                                                                <span className="text-dark fw-bold">{singleBookmark["name"]}</span>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" style={{ marginRight: "5px" }} onClick={() => { handler("getBookmarkReporting", { id: singleBookmark["id"] }) }}><i className="bi bi-eye"></i></a>
                                                                <a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" onClick={() => { deleteBookmark(singleBookmark["id"]) }}><i className="bi bi-trash"></i></a>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-9 col-lg-9">
                            <div className="card card-flush">
                                <div className="card-header border-0" style={{ minHeight: "380px" }}>
                                    <SearchBox callback={
                                        (method, searchFilters) => {
                                            switch (method) {
                                                case "generateReport":
                                                    generateReport(searchFilters);
                                                    break;
                                                case "createBookmark":
                                                    console.log("createBookmark", searchFilters);
                                                    handler("createBookmark", searchFilters);
                                                    break;
                                            }
                                        }
                                    } />
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        searchFilters["statExtraInfo"]["pieChart"] ?
                            <DrawPieChart searchFilters={searchFilters} /> :
                            <LineGraph searchFilters={searchFilters} />

                    }



                    {drawerSettings["visible"] ?
                        <RightDrawer heading={drawerSettings["heading"]} callback={handler}>
                            {drawerSettings["childName"] === "createBookmark" ? <CreateBookmark searchFilters={drawerSettings["meta"]} callback={(actionName) => {
                                switch (actionName) {
                                    case "reloadBookmarks":
                                        loadBookmarks();
                                        break;
                                    case "closeDrawer":
                                        handler("closeDrawer");
                                        break;
                                }
                            }}
                            /> :
                                ""}
                        </RightDrawer> : ""
                    }
                </div>
            </>
            }
        </>


)
}

export default ReferrerGroupManagerReporting;