import React, { useState, useEffect } from 'react';
import { useToken, useEntityType, useEntityId, useRole } from '../../hooks/useAuth';
import { getDeclinedList, performApplicationAdditionalActions } from '../../api';
import { CustomButton, InputField, ConfirmDialog } from '../HtmlComponents';
import { Navigate, NavigationType, useNavigate } from 'react-router-dom'
import Notifications from '../../components/global/Notifications';
import RightDrawer from "../HtmlComponents/modals/RightDrawer";
import DeclinedListItem from './DeclinedListItem';
import Pagination from '../HtmlComponents/Form/Pagination';

import Lookup from '../global/SearchForms/Lookup';
import SearchByDate from '../global/SearchForms/SearchByDate';
import Comments from '../applications/appComponents/Comments';
import Reinstate from './components/Reinstate';

const ListDeclined = () => {
    const [declinedList, setDeclinedList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filter, setFilter] = useState({ currentPageNumber: 1, startDate: 0, endDate: 0, q: "", referrerGroupId: "", referrerId: "" });
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: { width: "800" } });
    const [loading, setLoading] = useState(true);

    const token = useToken();
    const role = useRole();
    const entityType = useEntityType();
    const navigate = useNavigate();

    const fetchDeclinedList = async () => {
        setLoading(true);

        var query = "";
        if (filter["currentPageNumber"]) { query = `pageNumber=${filter["currentPageNumber"]}`; }
        if (filter["startDate"] > 0) { query += `&&startDate=${filter["startDate"]}`; }
        if (filter["endDate"] > 0) { query += `&&endDate=${filter["endDate"]}`; }
        if (filter["q"].length > 2) { query += `&&q=${filter["q"]}`; }

        if(entityType == "referrer"){
            var selectedReferrerId = localStorage.getItem("selectedReferrer");
            query = query+`&referrerId=${selectedReferrerId}`;
        }
        
        const response = await getDeclinedList(token, query);

        if (response["error"] !== undefined && response["error"] == 1) {
            Notifications("error", response["message"] || "Unable to fetch the settlements. Please contact IT")
        }
        else {
            var data = response["data"];
            if (data["totalRecords"] !== undefined) { setTotalRecords(data["totalRecords"]); }
            if (data["records"] !== undefined) { setDeclinedList(data["records"]); }

            console.log("data::::::::::::  ", data);
        }
        setLoading(false);
    }

    const updateHandler = async (name, value) => {
        switch (name) {
            case "currentPageNumber": setFilter({ ...filter, "currentPageNumber": value }); break;
            case "lookup":
                if (value.length > 2) { setFilter({ ...filter, "q": value, currentPageNumber: 1 }) }
                else if (value.length == 0) { setFilter({ ...filter, "q": "", currentPageNumber: 1 }) }
                break;
            case "dateFilter":
                console.log(`NAME::: ${name}  ---------  VALUE-------- ${value}`, value)
                setFilter({ ...filter, "startDate": value["startDate"], "endDate": value["endDate"], q: "", currentPageNumber: 1 });
                break;
            case "copyApplication":
                ConfirmDialog({ callback: () => { updateHandler("executeCopyApplicationOperation", value) }, "purpose": "general", "heading": `Are you sure you want to copy Ref # ${value}`, "description": "Documents will not be carried over." })
                break;    
            case "executeCopyApplicationOperation":
                var response = await performApplicationAdditionalActions(token, "copyApplication", value, {});
                console.log(response);
                if (response["error"] != undefined && response["error"] == 1) {
                    Notifications("error", response["message"] || "Some error occured.")
                }
                else {
                    Notifications("success", response["message"] || "Created Successfully.");
                    var applicationId = response["application"]["id"];
                    navigate(`/user/editConsumerApplication/${applicationId}`);
                }
                break;
             case "refreshListing": /**REFRESH THE LISTING WHEN A DELIONED IS REINSTATED */
                updateHandler("closeDrawer" , {});
                fetchDeclinedList();
                break;   
            case "viewComentsOnly": setDrawerSettings({ visible: true, heading: "Comments", childName: "Comments", meta: { applicationId: value['applicationId'] } }); break;
            case "reinstate": setDrawerSettings({ visible: true, heading: "Reinstate", childName: "reinstate", meta: { applicationId: value['applicationId'] , declinedId: value['declinedId']  } }); break;
            case "closeDrawer": setDrawerSettings({ visible: false }); break;
        }
    }

    useEffect(() => {
        fetchDeclinedList();
    }, []);

    useEffect(() => {
        fetchDeclinedList();
    }, [filter]);


    const TableHeader = () => {
        if (["finance", "superAdmin","admin"].includes(role)) {
            return (
                <>
                    <thead>
                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Ref #</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >First</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Surname</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Amount</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Reason for Decline </th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Decline Date</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Followup Date</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Assigned</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Referrer Group</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Referrer</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Individual Referrer</th>  
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Actions</th>
                        </tr>
                    </thead>
                </>
            )
        }
        else if (["analyst"].includes(role)) {
            return (
                <>
                    <thead>
                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Ref #</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >First</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Surname</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Amount</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Reason for Decline </th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Decline Date</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Followup Date</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Assigned</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Referrer Group</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Referrer</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Individual Referrer</th>  
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Actions</th>
                        </tr>
                    </thead>
                </>
            )
        }
        else if (entityType == "referrerGroup") {
            return (
                <>
                   <thead>
                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Ref #</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >First</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Surname</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Amount</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "250px" }} >Reason for Decline </th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Notes</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Decline Date</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Analyst</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Referrer</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Individual Referrer</th>                              
                        </tr>
                    </thead>
                </>
            )
        }
        else if (entityType == "referrer") {
            return (
                <>
                    <thead>
                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Ref #</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >First</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Surname</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Amount</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Purpose</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "250px" }} >Reason for Decline </th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Notes</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Decline Date</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Analyst</th>
                        </tr>
                    </thead>
                </>
            )
        }
        else {
            return (
                <>
                    <thead>
                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Ref #</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Referrer</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >First</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Surname</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Amount</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Purpose</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "250px" }} >Reason for Decline </th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Notes</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Decline Date</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Analyst</th>
                        </tr>
                    </thead>
                </>
            )
        }
    }

    return (
        <>
            <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">

                <SearchByDate callback={updateHandler} />
                <div className='row'>
                    <div className='col-sm-9'>
                        {/**label -> (Ref #, First Name, Surname, Amount, Purpose, Analyst ) */}
                        <Lookup label="" callback={updateHandler} />
                    </div>
                </div>



                <div className="card" data-select2-id="select2-data-132-dpd0">
                    <div className="card-body pt-0">
                        <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="table-responsive">
                                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                                    <TableHeader />
                                    <tbody className="fw-bold">
                                        {
                                            declinedList && Array.isArray(declinedList) ?
                                                declinedList.map(singleDeclined => (
                                                    <DeclinedListItem key={singleDeclined.id} singleDeclined={singleDeclined} callback={updateHandler} />
                                                )) : <tr>
                                                    <td colSpan={13}> <p> No Declined found</p> </td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Pagination totalRecords={totalRecords} currentPageNumber={filter["currentPageNumber"]} totalRecordsOnpage={10} updateCurrentPageNumber={updateHandler} />
            </div>

            {drawerSettings["visible"] ?
                <RightDrawer heading={drawerSettings["heading"]} callback={updateHandler}>
                    {drawerSettings["childName"] === "Comments" ? <Comments applicationId={drawerSettings["meta"]["applicationId"]} callback={updateHandler} /> : ""}
                    {drawerSettings["childName"] === "reinstate" ? <Reinstate applicationId={drawerSettings["meta"]["applicationId"]} declinedId={drawerSettings["meta"]["declinedId"]} callback={updateHandler} /> : ""}
                </RightDrawer> : ""
            }
        </>
    )
}

export default ListDeclined;