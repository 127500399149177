
import React, { useEffect, useState, useRef } from 'react';
import { useEntityType, useRole, useEntityId, useToken } from '../../hooks/useAuth';
import { FormSubmissionButton, CustomButton, Modal, MaskedField, RadioGroup, CheckBox, SelectField } from '../HtmlComponents';
import { Formik, Form, Field } from 'formik';
import { createEquifaxFees, updateEquifaxFees } from '../../api';
import Notifications from '../../components/global/Notifications';
import { EquifaxFeesValidationSchema } from './schema/EquifaxFeesValidationSchema';
import equifaxFeesSchema from './schema/equifaxFeesSchema';


import LoadingScreen from '../global/LoadingScreen';

//CONSTANTS

const AddEquifaxFees = ({callback}) => {
    const [singleEquifaxFees, setSingleEquifaxFees] = useState({});
    const [processing, setProcessing] = useState(false);

    const txnCodeOptions = [
        { value: "405", label: "VIC Land Titlte Search" },
        { value: "429", label: "VIC LTO Lands Indexes Address Search" },
        { value: "7W5", label: "Equifax Apply Base [ACSK]" },
        { value: "7WA", label: "Equifax Apply Base [ACSK] - with Commercial Data" },
        { value: "7WD", label: "Equifax Apply Base [ACSK] - with Comprehensive Consumer Data" },
        { value: "7WE", label: "Equifax Apply Base [ACSK] - with Comprehensive Consumer Data" }
    ];

    const token = useToken();

    /*  ********************* HTML COMPONENTS OF REFERRER GROUP  - START *****************/

    //IF LOGGED IN USER IS SUPER ADMIN THEN LIST THE CREDITREPS TOO IN THE DROPDOWN 






    /********************** HTML COMPONENTS OF REFERRER GROUP  - END *****************/


    return (
        <>
            <Formik
                initialValues={equifaxFeesSchema}
                validationSchema={EquifaxFeesValidationSchema}
                validateOnBlur={false}
                onSubmit={async (values) => {
                    setProcessing(true);
                    var response = await createEquifaxFees(token, values);
                    console.log(response);
                    if (response["error"]) {
                        Notifications("error", response["message"] || "Error: Unable to create. ")
                    }
                    else {
                        Notifications("success", response["message"] || "Created Successfully");
                        callback("addEquifaxFeesToList", "");

                    }
                    setProcessing(false);
                }}
            >
                {({ errors, touched, handleChange, setFieldValue }) => (
                    <Form>
                        <div id="kt_content_container" className="container-xxl">
                            <div className="col-xl-12">
                                <div>
                                    <label className="required fs-8 fw-bold mb-2">Name</label>
                                    <Field name="name" placeholder="Name" className="form-control form-control-solid" />
                                    {errors.name ? (<div className="error">{errors.name}</div>) : null}
                                </div>

                                <div>
                                    <label className="required fs-8 fw-bold mb-2">Txn Code</label>
                                    <Field name="txnCode">
                                            {({ field }) => (
                                                <SelectField
                                                    fieldName="txnCode"
                                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                                    options={txnCodeOptions}
                                                    setFieldValue={setFieldValue}
                                                />
                                            )}
                                        </Field>
                                        {errors.txnCode ? (<div className="error">{errors.txnCode}</div>) : null}
                                </div>

                                <div>
                                    <label className="required fs-8 fw-bold mb-2">Amount</label>
                                    <Field name="amount" placeholder="Amount" className="form-control form-control-solid" />
                                    {errors.amount ? (<div className="error">{errors.amount}</div>) : null}

                                </div>
                            </div>

                            <div className='mt-5'></div>          
                            <div className="col-xl-12">{JSON.stringify(errors).length > 2 ? <div className='error' style={{ float: "right" }}>Please fix the above errors first. </div> : ""}</div>
                            <div className="col-xl-12 " style={{float: "right"}}> {<FormSubmissionButton isActive={JSON.stringify(errors).length < 3 ? true : false} />}</div>

                        </div>
                    </Form>
                )}
            </Formik>

            {
                processing ? <LoadingScreen /> : ""
            }
        </>
    )




}

export default AddEquifaxFees;