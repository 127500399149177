import React, { useEffect, useState, useRef } from 'react';
import { useToken } from '../../hooks/useAuth';
import { FormSubmissionButton, SelectField, CheckBox, MultiSelectField , MaskedField } from '../HtmlComponents';
import { Formik, Form, Field } from 'formik';
import { addUser } from "../../api";
import { AddUserSchema } from "./schema/UserSchema";
import ErrorMessage from '../global/errors/Error';
import Notifications from '../../components/global/Notifications';
import LoadingScreen from '../global/LoadingScreen';
import Constants from '../global/Constants';
import{dateFilter , convertDateintoUnix , formatDate} from '../../helpers';

const AddUser = ({ entityType, entityId, callback, contacts = [] }) => {
    const [error, setError] = useState({ "exist": false, "message": "" });
    //let { entityType, entityId } = useParams();
    const [user, setUser] = useState({
        userName: '', firstName: '', surname: '', email: '', password: '', role: "", entityType: entityType, entityId: entityId, contactNumber: '', landlineNumber: '',
        extraInfo: {
            occupations: [],
            additionalInfo: {
                applicationUpdatesForAllSystemUsers: false,
                systemAccessGranted: false,
                exportEmail: false,
                statusNotificationViaEmail: false,
                statusNotificationViaSMS: false
            }
        },
        isTwoFaEnabled: false
    });
    const [roles, setRoles] = useState([]);
    const [referrerUserOccupations, setReferrerUserOccupations] = useState([]);

    const [contatsList, setContactsList] = useState([]);

    const token = useToken();

    //LOAD REFERRER USER OCCUPATIONLIST
    const loadReferrerUserOccupations = () => {
        var occupations = Constants.REFERRER_OCCUPATIONS_LIST;
        var options = [];
        Object.keys(occupations).map(key => {
            var option = { value: key, label: occupations[key] }
            options.push(option)
        })
        setReferrerUserOccupations(options);
    }

    //ASSIGN ROLE TO 
    const updateRolesList = () => {
        var options = [];
        switch (entityType) {
            case "creditRep":
                options = [{ value: "admin", label: "Admin" }, { value: "analyst", label: "Analyst" }, { value: "referrerGroupManager", label: "Referrer Group Manager" }];
                setRoles(options);
                break;
            case "company":
                options = [{ value: "superrAdmin", label: "Super Admin" }, { value: "finance", label: "Finance" }, { value: "auditor", label: "Auditor" }];
                setRoles(options);
                break;
        }
    }

    //RETURN ROLE ACCORDING TO ENTITY TYPE
    const getRole = () => {
        var role = "";
        switch (entityType) {
            case "lender": role = "user"; break;
            case "referrer": role = "user"; break;
            case "referrerGroup": role = "user"; break;
            case "creditRep": role = ""; break;
        }
        return role;
    }

    //UPDATE THE USER ROLE ACCORDING TO THE ENTITY TYPE
    useEffect(() => {
        loadReferrerUserOccupations();
        setUser({ ...user, role: getRole() });
        updateRolesList();  //CREATE ROLES LIST

        //PREPARE DROPDOWN LIST FROM CONTACTS
        if (Array.isArray(contacts)) {
            var tmpContactList = contacts.map((contact) => {
                return { value: contact.id, label: contact.firstName }
            })
            setContactsList(tmpContactList);
        }
    }, [])


    //CUSTOM PERMISSIONS FOR ANALYST  (DELETE-APPLICATION, RE-INSTATE-APPLICATION )
    const AdditionalPermissions = (role, errors, touched, setFieldValue) => {
        if (role !== "analyst") {
            return "";
        }
        return (
            <>
                <div className="row">
                    <div className="col-sm-12">
                        <label className=" fs-8 fw-bold mb-2">Additional Permissions</label>
                    </div>
                    <div className="col-sm-12">
                        <table className="table gy-1 fw-bolder dataTable no-footer">
                            <tbody>
                                <tr>
                                    <td className="text-start" style={{ fontWeight: "normal", width: "20px" }}>
                                        <Field name="extraInfo.additionalPermissions.deleteApplication">
                                            {({ field }) => (
                                                <CheckBox name="extraInfo.additionalPermissions.deleteApplication" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                            )}
                                        </Field>
                                    </td>
                                    <td className="text-start">Delete Application</td>
                                </tr>
                                <tr>
                                    <td className="text-start" style={{ fontWeight: "normal", width: "20px" }}>
                                        <Field name="extraInfo.additionalPermissions.reInstateApplication">
                                            {({ field }) => (
                                                <CheckBox name="extraInfo.additionalPermissions.reInstateApplication" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                            )}
                                        </Field>
                                    </td>
                                    <td className="text-start">Re-Instate Application</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }
    

    //HTML SECTION ONLY IF ENTITYTYPE IS REFERRER (ADDING REFERRER USER)
    const additionalComponentForReferrerUser = (errors, touched, setFieldValue) => {
        if (entityType !== "referrer") {
            return "";
        }
        else {
            //COnstant.REFERRER_OCCUPATIONS_LIST
            return (
                <>
                    <div className="row">
                        <div className="col-sm-12">
                            <table className="table gy-1 fw-bolder dataTable no-footer">
                                <tbody>
                                    <tr>
                                        <td colSpan={2} className="text-start">Position</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <div className="mb-3">
                                                <Field name="extraInfo.occupations">
                                                    {({ field }) => (
                                                        <MultiSelectField
                                                            fieldName="extraInfo.occupations"
                                                            options={referrerUserOccupations}
                                                            defaultSelectedOptions={field.value}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-start" style={{ fontWeight: "normal", width: "20px" }}>
                                            <Field name="extraInfo.additionalInfo.applicationUpdatesToAllSystemUsers">
                                                {({ field }) => (
                                                    <CheckBox name="extraInfo.additionalInfo.applicationUpdatesToAllSystemUsers" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                        </td>
                                        <td className="text-start">Application updates to all system users</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start" style={{ fontWeight: "normal", width: "20px" }}>
                                            <Field name="extraInfo.additionalInfo.allowToSubmitApplication">
                                                {({ field }) => (
                                                    <CheckBox name="extraInfo.additionalInfo.allowToSubmitApplication" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                        </td>
                                        <td className="text-start">Allow to submit applications</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start" style={{ fontWeight: "normal" }}>
                                            <Field name="extraInfo.additionalInfo.systemAccessGranted">
                                                {({ field }) => (
                                                    <CheckBox name="extraInfo.additionalInfo.systemAccessGranted" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                        </td>
                                        <td className="text-start">System access granted</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start" style={{ fontWeight: "normal" }}>
                                            <Field name="extraInfo.additionalInfo.exportEmail">
                                                {({ field }) => (
                                                    <CheckBox name="extraInfo.additionalInfo.exportEmail" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                        </td>
                                        <td className="text-start">Export email (CMS)</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start" style={{ fontWeight: "normal" }}>
                                            <Field name="extraInfo.additionalInfo.statusNotificationViaEmail">
                                                {({ field }) => (
                                                    <CheckBox name="extraInfo.additionalInfo.statusNotificationViaEmail" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                        </td>
                                        <td className="text-start">Email (Status Notifications)</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start" style={{ fontWeight: "normal" }}>
                                            <Field name="extraInfo.additionalInfo.statusNotificationViaSMS">
                                                {({ field }) => (
                                                    <CheckBox name="extraInfo.additionalInfo.statusNotificationViaSMS" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                        </td>
                                        <td className="text-start">SMS (Status Notifications)</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>

            )
        }
    }

    //ONLY FOR CREDIT REP USERS(ADMIN, ANALYST) AND REFERRER GROUP USERS(I.E ROHAN )  => IF entityType IS CREDITREP OR REFERRERGROUP
    const LicenseField = ({ errors, touched, setFieldValue, handleChange }) => {
        if (!["creditRep", "referrerGroup"].includes(entityType)) { return ""; }
        else {
            return (
                <>
                    <div className="col-sm-12 col-md-6">
                        <label className=" fs-8 fw-bold mb-2  mt-4">Credit License Date </label>
                        <Field type="text" name="extraInfo.creditLicenseDate" placeholder="dd/mm/yyyy" className="form-control form-control-solid" onChange={handleChange}>
                            {({ field }) => (
                                <MaskedField fieldName="extraInfo.creditLicenseDate" fieldValue={dateFilter(field.value)} fieldPlaceholder="dd/mm/yyyy" maskRule="date" setFieldValue={setFieldValue} />
                            )}
                        </Field>
                        {errors.extraInfo && errors.extraInfo.creditLicenseDate ? (<div className="error">{errors.extraInfo.creditLicenseDate}</div>) : null}
                    </div>
                </>
            )
        }

    }

    const userFormHtmlClone = (errors, touched, setFieldValue , handleChange) => {
        return (<>
            <div className="card mb-xl-4">
                <div className="card-body p-0">

                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <label className=" fs-8 fw-bold mb-2">UserName</label>
                            <Field name="userName" placeholder="User Name" className="form-control form-control-solid" autoComplete="off" />
                            {errors.userName ? (<div className="error">{errors.userName}</div>) : null}
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <label className=" fs-8 fw-bold mb-2">First Name </label>
                            <Field name="firstName" placeholder="First Name" className="form-control form-control-solid" />
                            {errors.firstName ? (<div className="error">{errors.firstName}</div>) : null}
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <label className=" fs-8 fw-bold mb-2 mt-4">Surname </label>
                            <Field name="surname" placeholder="Surname" className="form-control form-control-solid" />
                            {errors.surname ? (<div className="error">{errors.surname}</div>) : null}
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <label className=" fs-8 fw-bold mb-2  mt-4">Email</label>
                            <Field name="email" placeholder="Email" className="form-control form-control-solid" />
                            {errors.email ? (<div className="error">{errors.email}</div>) : null}
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <label className=" fs-8 fw-bold mb-2  mt-4">Mobile Number </label>
                            <Field name="contactNumber" placeholder="Mobile Number" className="form-control form-control-solid" />
                            {errors.contactNumber ? (<div className="error">{errors.contactNumber}</div>) : null}
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <label className=" fs-8 fw-bold mb-2  mt-4">Landline Number </label>
                            <Field name="landlineNumber" placeholder="Landline Number" className="form-control form-control-solid" />
                            {errors.landlineNumber ? (<div className="error">{errors.landlineNumber}</div>) : null}
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <label className=" fs-8 fw-bold mb-2  mt-4">Password</label>
                            <Field type="password" name="password" placeholder="Password" className="form-control form-control-solid" />
                            {errors.password ? (<div className="error">{errors.password}</div>) : null}
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div style={{ marginTop: "44px" }}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="text-start" style={{ fontWeight: "normal", width: "30px" }}>
                                                <Field name="isTwoFaEnabled">
                                                    {({ field }) => (
                                                        <CheckBox name="isTwoFaEnabled" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                    )}
                                                </Field>
                                            </td>
                                            <td className="text-start">2 FA Eabled</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            {roles.length > 0 ?
                                <div>
                                    <label className=" fs-8 fw-bold mb-2  mt-4">Role </label>
                                    <Field name="role">
                                        {({ field }) => (
                                            <SelectField
                                                fieldName="role"
                                                defaultSelectedOption={{ value: field.value }}
                                                options={roles}
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                </div>
                                : ""

                            }
                        </div>

                        <LicenseField setFieldValue={setFieldValue} errors={errors} handleChange={handleChange} />
                    </div>
                </div>
            </div >
        </>)
    }



    const userFormHtml = (errors, touched, setFieldValue) => {
        return (<>
            <div className="card mb-xl-8">
                <div className="card-body p-0">

                    <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                        <tbody className="fs-6">
                            <tr className="even">
                                <td className="text-start">UserName</td>
                                <td className="text-start text-gray-600">

                                    <Field name="userName" placeholder="User Name" className="form-control form-control-solid" />
                                    {errors.userName ? (<div>{errors.userName}</div>) : null}

                                </td>
                            </tr>

                            <tr className="even">
                                <td className="text-start">First Name</td>
                                <td className="text-start text-gray-600"><Field name="firstName" placeholder="First Name" className="form-control form-control-solid" />
                                    {errors.firstName ? (<div>{errors.firstName}</div>) : null}</td>
                            </tr>
                            <tr className="even">
                                <td className="text-start">Surname</td>
                                <td className="text-start text-gray-600"><Field name="surname" placeholder="Surname" className="form-control form-control-solid" />
                                    {errors.surname ? (<div>{errors.surname}</div>) : null}</td>
                            </tr>
                            <tr className="even">
                                <td className="text-start">Email</td>
                                <td className="text-start text-gray-600"><Field name="email" placeholder="Email" className="form-control form-control-solid" />
                                    {errors.email ? (<div>{errors.email}</div>) : null}</td>
                            </tr>
                            <tr className="even">
                                <td className="text-start">Mobile Number</td>
                                <td className="text-start text-gray-600"><Field name="contactNumber" placeholder="Mobile Number" className="form-control form-control-solid" />
                                    {errors.contactNumber ? (<div>{errors.contactNumber}</div>) : null}</td>
                            </tr>
                            <tr className="even">
                                <td className="text-start">Lanline Number</td>
                                <td className="text-start text-gray-600"><Field name="landlineNumber" placeholder="Landline Number" className="form-control form-control-solid" />
                                    {errors.landlineNumber ? (<div>{errors.landlineNumber}</div>) : null}</td>
                            </tr>
                            <tr className="even">
                                <td className="text-start">Password</td>
                                <td className="text-start text-gray-600"><Field type="password" name="password" placeholder="Password" className="form-control form-control-solid" />
                                    {errors.password ? (<div>{errors.password}</div>) : null}</td>
                            </tr>


                            {roles.length > 0 ?
                                <tr className="even">
                                    <td className="text-start">Role</td>
                                    <td className="text-start text-gray-600">
                                        <Field name="role">
                                            {({ field }) => (
                                                <SelectField
                                                    fieldName="role"
                                                    defaultSelectedOption={{ value: field.value }}
                                                    options={roles}
                                                    setFieldValue={setFieldValue}
                                                />
                                            )}
                                        </Field>
                                    </td>
                                </tr> : ""
                            }
                        </tbody>
                    </table>

                </div>
            </div>
        </>)
    }

    //SEARCH CONTACT AND UPDATE THE USER STATE 
    const updateHandler = (name, value) => {
        //find the selectec contact from the contacts list
        var tmpContact = contacts.find(contact => value == contact.id)

        if (tmpContact !== undefined && tmpContact["id"]) {
            var fContact = {
                userName: '',
                firstName: tmpContact["firstName"],
                surname: "",
                email: tmpContact["email"],
                password: '',
                role: getRole(),
                entityType: entityType,
                entityId: entityId,
                contactNumber: tmpContact["contactNumber"]
            }
            setUser(fContact);
        }
    }

    const ContactDropDownHtml = () => {
        return <>

            <div id="kt_content_container" className="container-xxl">
                <div className="row g-12 g-xl-12">
                    <h4 className="text-start mb-2">Contacts</h4>
                    <SelectField
                        fieldName="contactsList"
                        defaultSelectedOption={{ value: 0, label: "" }}
                        options={contatsList}
                        setFieldValue={updateHandler}
                        callback=""
                    />
                </div>
            </div>
        </>
    }
    //REDIRECTION HANDLING
    const LoadView = () => {
        return <>
            {Array.isArray(contacts) && contacts.length > 0 ? <ContactDropDownHtml /> : ""}

            <Formik
                initialValues={user}
                validationSchema={AddUserSchema}
                validateOnBlur={true}
                onSubmit={async (values) => {
                    setError("");
                    //console.log("VALUES:::::::::: ", values);
                    const response = await addUser(token, values);
                    if (response["error"]) {
                        Notifications("error", response["message"] || "Error")
                        setUser(values);
                    } else {
                        // console.log("response.id : " + response.user.id);
                        Notifications("success", response["message"] || "Created Successfully")
                        //CHECK IF RELEVANT ENTITY PATH IS AVAILABLE                                       
                        //console.log(response);
                        callback(response.user);
                    }
                }}
            >
                {({ values, errors, touched, setFieldValue , handleChange }) => (
                    <Form>
                        <div id="kt_content_container" className="container-xxl">
                            <div className="row g-12 g-xl-12">
                                <ErrorMessage isErrorExist={error["exist"]} errorMessage={error["message"]} />
                                <div className="col-xl-12">
                                    {userFormHtmlClone(errors, touched, setFieldValue , handleChange)}
                                    {additionalComponentForReferrerUser(errors, touched, setFieldValue)}
                                    {AdditionalPermissions(values.role, errors, touched, setFieldValue)}
                                    {<FormSubmissionButton isActive={true} />}
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

        </>
    }

    return (
        <LoadView />
    );
}


export default AddUser;