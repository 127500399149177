import { useEffect, useState } from "react";
import { SelectField, CustomButton, MultiSelectField, CustomMultiSelectWithCheckBoxes } from "../../../HtmlComponents";
import UserDefinedPeriod from "../UserDefinedPeriod";
import { formatDate, getPreviousMonth, getCurrentMonth, convertDateintoUnix, getLastDayOfMonth, getStartAndEndDateForGivenPeriod } from '../../../../helpers';
import ReportingConstants from '../ReportingConstants';
import Constants from "../../../global/Constants";
import { getUsers, getReferrersList, getReferrerGroupsList, getCreditRepList, loadReporting } from '../../../../api';
import { useEntityId, useEntityType, useRole, useToken } from "../../../../hooks/useAuth";
import Notifications from "../../../global/Notifications";

const SearchBox = ({ callback }) => {
    //STATES FOR STORING DYNAMIC DATA REQUIRED FOR REPORTING
    const [reportForExtraInfoOptions, setReportForExtraInfoOptions] = useState([]);
    const [analysts, setAnalysts] = useState([]);
    const [referrers, setReferrers] = useState([]);
    const [referrerGroups, setReferrerGroups] = useState([]);
    const [creditReps, setCreditReps] = useState([]);
    const [stats, setStats] = useState([]);
    const [statExtraInfoOptions, setStatExtraInfoOptions] = useState([]);
    const [allowSubmission, setAllowSubmission] = useState(false);

    /*
    parentEntityId - IT WILL BE USED ONLY WHEN THE ENTITY HAS SUB ENTITIES LIKE WE SELECT INDIVIDUAL REFERRER/STAFF FOR  'REPORT FOR' 
    THEN WE NEED TO SELECT THE REFERRER FROM THE NEXT DROPDOWN TO LOAD THE INDIVIDUAL REFERRERS AGAINST THAT REFERRER

   */
    const [parentEntityId, setParentEntityId] = useState(0);


    //periodicType  => day | week | month
    const [searchFilters, setSearchFilters] = useState({
        reportFor: "",                  //REPORT FOR
        reportForExtraInfo: [],         //CONTIANS THE IDS OR VALUES OF THE SELECTED REPORT-FOR-EXTRA-INFO (I.G FOR REFERRER GROUP IT WILL HAVE 2,5,6  ; FOR STATES IT WILL HAVE VIC, NSW ETC)
        stat: "",                       //STAT NAME
        statExtraInfo: { subStat: "", pieChart: false }, //STAT EXTRA INFO IF A STAT HAS SUBSTATS FOR EXAMPLE "statsForAllIndividualReasonsForDeclinedPercentage" HAS SUBSTATS (ALL OF THE DECLINED REASON SO 1 NEEEDS TO BE SELECTED IN ORDER TO PROCEED)
        periodicType: "",
        period: { name: "", startDate: "", endDate: "" },
        comparePeriod: { name: "", startDate: "", endDate: "" }
    })

    const [pieChartSupportedStat, setPieChartSupportedStat] = useState(false);
    const token = useToken();
    const entityType = useEntityType();
    const entityId = useEntityId();
    const userRole = useRole();

    useEffect(() => {
        //LOAD REFERRERS
        const loadReferrers = async () => {
            let response = await getReferrersList(token, 'active');

            if (response["error"] !== undefined && response["error"] === 0) {
                let options = [];
                response["referrers"].map((singleItem) => { options.push({ label: singleItem["tradingName"], value: singleItem["id"] }) });
                setReferrers(options);
            }

        }
        loadReferrers();

        //LOAD REFERRER GROUPS
        const loadReferrerGroups = async () => {
            let response = await getReferrerGroupsList(token);
            //console.log("   response fro referrer groups--------------   ", response);
            if (response["error"] !== undefined && response["error"] === 0) {
                let options = [];
                response["referrerGroups"].map((singleItem) => { options.push({ label: singleItem["tradingName"], value: singleItem["id"] }) });
                setReferrerGroups(options);
            }
        }
        loadReferrerGroups();
    }, [])

    //LAOD INDIVIDUAL REFERERS AGAINST SELECTED REFERRER
    const loadIndividualReferrers = async (referrerId) => {
        let response = await getUsers(token, "referrer", referrerId, "reporting");
        //console.log("   response fro users--------------   ", response);
        if (response["error"] !== undefined && response["error"] === 0) {
            let options = [];
            response["data"].map((singleItem) => { options.push({ label: singleItem["firstName"] + " " + singleItem["surname"], value: singleItem["id"] }) });
            setReportForExtraInfoOptions(options);
        }
    }

    //CONTROLE ENABLE|DISABLE SUBMISSION BUTTIN 
    useEffect(() => {
        let submissionAllowed = 1;

        submissionAllowed = searchFilters["reportFor"] === "" ? 0 : submissionAllowed;
        submissionAllowed = searchFilters["stat"] === "" ? 0 : submissionAllowed;
        submissionAllowed = searchFilters["period"]["name"] === "" ? 0 : submissionAllowed;
        submissionAllowed = searchFilters["period"]["startDate"] === 0 ? 0 : submissionAllowed;
        submissionAllowed = searchFilters["period"]["endData"] === 0 ? 0 : submissionAllowed;

        if (searchFilters["comparePeriod"]["name"] !== "none") {
            submissionAllowed = searchFilters["comparePeriod"]["startDate"] === 0 ? 0 : submissionAllowed;
            submissionAllowed = searchFilters["comparePeriod"]["endDate"] === 0 ? 0 : submissionAllowed;
        }

        //SOME STATS HAVE SUBSTATS SO IF A STAT HAS SUBSTATS THEN SUBSTAT MUST BE SELECTED
        if (["statsForAllIndividualReasonsForDeclinedPercentage"].includes(searchFilters["stat"])
            && searchFilters["statExtraInfo"]["subStat"] == ""
            && searchFilters["statExtraInfo"]["pieChart"] == false) { /*submissionAllowed = 0;*/ }

        //IF REPORT-FOR IS individualReferrer THEN reportForExtraInfo MUST BE SELECTED
        if (["individualReferrer"].includes(searchFilters["reportFor"]) && searchFilters["reportForExtraInfo"].length === 0) { submissionAllowed = 0; }

        //CHECK IF searchFilters["reportForExtraInfo"].length > 10 THEN GIVES AN ERROR MESSAGE THAT MAXIMUM 15 CAN BE SELECTED AT A TIME
        if (searchFilters["reportForExtraInfo"].length > 15) {
            setAllowSubmission(false);
            Notifications("error", "Maximum 15 can be selected at a time");
        }

        if (!allowSubmission && submissionAllowed === 1) { setAllowSubmission(true); }
        else if (allowSubmission && submissionAllowed === 0) { setAllowSubmission(false); }


        //IF REPORT FOR IS NOT individualReferrer THEN RESET THE PARENT ENTITY ID
        if (!["individualReferrer"].includes(searchFilters["reportFor"]) && parentEntityId !== 0) { setParentEntityId(0); }

    }, [searchFilters])


    const handler = async (name, value, options = { startDate: 0, endDate: 0 }) => {

        switch (name) {
            case "reportFor":
                //setSearchFilters({ ...searchFilters, reportFor: value });
                setSearchFilters(prevState => ({ ...prevState, reportFor: value, reportForExtraInfo: [] })); //RESET reportForExtraInfo TO []
                setReportForExtraInfoOptions([]);

                switch (value) {
                    case "allBrokersCombinedListed": setReportForExtraInfoOptions(analysts); break;
                    case "referrer": setReportForExtraInfoOptions(referrers); break;
                    case "allBrokersCombinedListed": setReportForExtraInfoOptions(analysts); break;
                    case "referrerGroup": setReportForExtraInfoOptions(referrerGroups); break;
                    case "creditReps": setReportForExtraInfoOptions(creditReps); break;
                    case "state": setReportForExtraInfoOptions(ReportingConstants.STATES); break;
                    default: setReportForExtraInfoOptions([]); break;
                }
                loadStats(value); //UPDATE STAT OPTIONS BASED ON THE SELECTED REPORT-FOR
                                
                break;
            case "reportForExtraInfo": setSearchFilters(prevState => ({ ...prevState, reportForExtraInfo: value })); break;
            case "stat":

                //CHECK IF PIE CHART IS SUPPORTED FOR THIS STAT
                let pieChartSupportedForSelectedStat = ReportingConstants.PIE_CHART_SUPPORTED_STATS !== undefined && ReportingConstants.PIE_CHART_SUPPORTED_STATS.includes(value) ? true : false;

                setPieChartSupportedStat(pieChartSupportedForSelectedStat);
                //SET STAT EXTRA INFO AND STAT VALUE
                setSearchFilters({ ...searchFilters, stat: value, statExtraInfo: { ...searchFilters["statExtraInfo"], subStat: "", pieChart: false } });
                //FETCH STAT EXTRAINFO OPTIONS
                if (ReportingConstants.STAT_OPTIONS[value] !== undefined) { setStatExtraInfoOptions(ReportingConstants.STAT_OPTIONS[value]); }
                else { setStatExtraInfoOptions([]); }



                break;
            case "statExtraInfo":
                setSearchFilters(prevState => ({ ...prevState, statExtraInfo: { ...searchFilters["statExtraInfo"], subStat: value } }));
                break;
            case "pieChart":
                setSearchFilters(prevState => ({ ...prevState, statExtraInfo: { ...searchFilters["statExtraInfo"], pieChart: value } }));
                break;
            case "period":
                let periodDateTimeRange = { startDate: 0, endDate: 0 }
                if (value === "userDefinedPeriod") {
                    periodDateTimeRange["startDate"] = options["startDate"];
                    periodDateTimeRange["endDate"] = options["endDate"];
                }
                else {
                    //GET START AND END DATE AGAINST SELECTED PERIOD          
                    periodDateTimeRange = getStartAndEndDateForGivenPeriod(value);
                }

                //UPDATE STATE
                setSearchFilters(prevState => ({
                    ...prevState,
                    ["period"]: {
                        ...prevState["period"],
                        name: value,
                        startDate: periodDateTimeRange["startDate"] != 0 ? convertDateintoUnix(periodDateTimeRange["startDate"], "YYYY-MM-DD H:mm:s") : 0,
                        endDate: periodDateTimeRange["endDate"] != 0 ? convertDateintoUnix(periodDateTimeRange["endDate"], "YYYY-MM-DD H:mm:s") : 0
                    },
                    periodicType: ReportingConstants["periodPeriodicType"][value]
                }));
                break;

            case "comparePeriod":
                //GET START AND END DATE AGAINST SELECTED PERIOD
                let cpOptions = {};  //COMPARE PERIOD OPTIONS
                let comparePeriodDateTimeRange = { startDate: 0, endDate: 0 }


                if (value === "userDefinedPeriod") {
                    comparePeriodDateTimeRange["startDate"] = options["startDate"];
                    comparePeriodDateTimeRange["endDate"] = options["endDate"];
                }
                else {
                    comparePeriodDateTimeRange = getStartAndEndDateForGivenPeriod(value, value === "samePeriodLastYear" ?
                        {
                            startDate: formatDate(searchFilters["period"]["startDate"], "unix", "YYYY-MM-DD HH:mm:s"),
                            endDate: formatDate(searchFilters["period"]["endDate"], "unix", "YYYY-MM-DD HH:mm:s")
                        } : {});
                }
                //UPDATE STATE
                setSearchFilters(prevState => ({
                    ...prevState,
                    ["comparePeriod"]: {
                        ...prevState["comparePeriod"],
                        name: value,
                        startDate: comparePeriodDateTimeRange["startDate"] != 0 ? convertDateintoUnix(comparePeriodDateTimeRange["startDate"], "YYYY-MM-DD H:mm:s") : 0,
                        endDate: comparePeriodDateTimeRange["endDate"] != 0 ? convertDateintoUnix(comparePeriodDateTimeRange["endDate"], "YYYY-MM-DD H:mm:s") : 0
                    },
                }));
                break;

            case "referrerSelectionForIndividualReferrerField":
                //LOAD THE RELEVANT INDIVIDUAL REFERERS INTO ENTITY DROPDOWN
                loadIndividualReferrers(value);
                setParentEntityId(value);

                break;
            case "clearFilters":
                setSearchFilters(prevState => ({
                    ...prevState,
                    reportFor: "",
                    reportForExtraInfo: [],
                    stat: "",
                    statExtraInfo: {
                        ...prevState["statExtraInfo"],
                        subStat: "",
                        pieChart: false
                    },
                    period: {
                        ...prevState["period"],
                        periodicType: "",
                        startDate: 0,
                        endDate: 0
                    },
                    comparePeriod: {
                        ...prevState["comparePeriod"],
                        periodicType: "",
                        startDate: 0,
                        endDate: 0
                    }
                }));
                setStatExtraInfoOptions([]);        //RESET STAT EXTRA INFO OPTIONS
                setPieChartSupportedStat(false);    //RESET PIE CHART SUPPORTED STAT

        }
    }

    //LOAD STATS BASED ON THE SELECTED REPORT-FOR AND LOGGED IN USER ENTITY TYPE
    const loadStats = async (reportFor) => {

        let updatedUserRole = '';
        switch (true) {
            case userRole === "superAdmin": updatedUserRole = "superAdmin"; break;
            case userRole === "admin": updatedUserRole = "admin"; break;
            case userRole === "analyst": updatedUserRole = "analyst"; break;
            case userRole === "user" && entityType === 'referrerGroup' : updatedUserRole = "referrerGroupUser"; break;
            case userRole ===  "referrerGroupManager" : updatedUserRole = "referrerGroupManager"; break;

        }

        let filteredStats = ReportingConstants.stats.filter((singleStat) => singleStat["authorized"] !== undefined && singleStat["authorized"][updatedUserRole] !== undefined  && Array.isArray(singleStat["authorized"][updatedUserRole])  && singleStat["authorized"][updatedUserRole].includes(reportFor)  && singleStat["status"] === "active");

        //console.log("filtered stats :::::::::::::  "    , filteredStats);
        setStats(filteredStats);
    }



    return (<>
        <div className="card card-flush h-md-50 mb-5 mb-xl-10">
            <div className="card-body">
                <div className="row">
                    <div className="col-sm-12 ">
                        <div className="row">
                            <div className="col-sm-3">
                                <label style={{ fontSize: "10px", fontWeight: "600" }}>Report For</label>
                                <SelectField
                                    fieldName="reportFor"
                                    defaultSelectedOption={{ value: searchFilters["reportFor"], label: "" }}
                                    options={ReportingConstants.reportForOptions.filter((singleItem) => singleItem["authorizedEntity"].includes(entityType) && singleItem["authorizedRole"].includes(userRole) && singleItem["status"] === "active")}
                                    setFieldValue={handler}
                                />
                            </div>

                            {/** REFERRER DROPDOWN IF REPORT-FOR IS individualReferrer SO LIST THE REFERRERS HERE AND THEN LOAD THE RELEVANT INDIVIDUAL REFERERS INTO ENTITY DROPDOWN **/}
                            {
                                !["individualReferrer"].includes(searchFilters["reportFor"]) ? "" :
                                    <div className="col-sm-9 ">
                                        <label style={{ fontSize: "10px", fontWeight: "600" }}>Select Referrer</label>
                                        <SelectField
                                            fieldName="referrerSelectionForIndividualReferrerField"
                                            defaultSelectedOption={{ value: parentEntityId, label: "" }}
                                            options={referrers}
                                            setFieldValue={handler}
                                        />
                                    </div>
                            }

                            {/** ENTITY DROPDOWN **/}
                            {
                                ["allNFAL"].includes(searchFilters["reportFor"]) ? "" :
                                    <div className={["individualReferrer"].includes(searchFilters["reportFor"]) ? "col-sm-12 mt-3" : "col-sm-9"}>
                                        <label style={{ fontSize: "10px", fontWeight: "600" }}>Select Entity {["individualReferrer"].includes(searchFilters["reportFor"]) ? "(Required)" : "(optional)"}</label>
                                        <MultiSelectField
                                            fieldName="reportForExtraInfo"
                                            defaultSelectedOptions={searchFilters["reportForExtraInfo"]}
                                            options={reportForExtraInfoOptions}
                                            setFieldValue={handler}
                                        />
                                    </div>
                            }

                            {/** LOAD STTAS BASED ON SELECTINO OF 'REPORT FOR' AND LOGGED IN USER ENTITY TYPE */}
                            <div className="col-sm-12 mt-3">
                                <label style={{ fontSize: "10px", fontWeight: "600" }}>Stat</label>
                                <SelectField
                                    fieldName="stat"
                                    defaultSelectedOption={{ value: searchFilters["stat"], label: "" }}
                                    options={stats.filter((singleItem) => singleItem["status"] === "active")}
                                    setFieldValue={handler}
                                />
                            </div>

                            {
                                searchFilters?.["stat"] !== "statsForAllIndividualReasonsForDeclinedPercentage" &&
                                Array.isArray(statExtraInfoOptions) && statExtraInfoOptions.length > 0 ?
                                    <div className="col-sm-12 mt-3">
                                        <label style={{ fontSize: "10px", fontWeight: "600" }}>Select Stat options</label>
                                        <MultiSelectField
                                            fieldName="statExtraInfo"
                                            //defaultSelectedOption={{ value: searchFilters["statExtraInfo"]["subStat"], label: "" }}
                                            defaultSelectedOptions={searchFilters["statExtraInfo"]["subStat"]}
                                            options={statExtraInfoOptions}
                                            setFieldValue={handler}
                                        />
                                    </div> : ""
                            }

                            {
                                
                                searchFilters?.["stat"] === "statsForAllIndividualReasonsForDeclinedPercentage" &&
                                 Array.isArray(statExtraInfoOptions) && statExtraInfoOptions.length > 0 ?
                                    <div className="col-sm-12 mt-3">
                                        <label style={{ fontSize: "10px", fontWeight: "600" }}>Select Stat options</label>                                        
                                        <CustomMultiSelectWithCheckBoxes
                                            options={statExtraInfoOptions}
                                            fieldName="statExtraInfo"
                                            setFieldValue={ handler}
                                        />
                                    </div> : "" 
                                    
                            }

                            {
                                //IF STAT HAS PIE CHART SUPPORT THEN SHOW THE PIE CHART CHECKBOX
                                pieChartSupportedStat === true ?
                                    <div className="col-sm-12 mt-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value={searchFilters["statExtraInfo"]["pieChart"]} id="flexCheckDefault" onChange={(e) => { handler("pieChart", e.target.checked) }} />
                                            <label className="form-check-label" htmlFor="flexCheckDefault" style={{ fontSize: "10px", fontWeight: "600" }}>
                                                Create Pie Chart
                                            </label>
                                        </div>
                                    </div> : ""
                            }


                            <div className="col-sm-12 mt-3"></div>
                            <div className="col-sm-3">
                                <label style={{ fontSize: "10px", fontWeight: "600" }}>Period</label>
                                <SelectField
                                    fieldName="period"
                                    defaultSelectedOption={{ value: searchFilters["period"]["name"], label: "" }}
                                    options={ReportingConstants.period.filter((singleItem) => singleItem["status"] === "active")}
                                    setFieldValue={handler}
                                />
                            </div>
                            {searchFilters["period"]["name"] === "userDefinedPeriod" ? <div className="col-sm-9">
                                <UserDefinedPeriod callback={(name, value) => { handler("period", "userDefinedPeriod", value) }} />
                            </div> : ""}

                            <div className="col-sm-12 mt-3"></div>

                            <div className="col-sm-3">
                                <label style={{ fontSize: "10px", fontWeight: "600" }}>Compare Period</label>
                                <SelectField
                                    fieldName="comparePeriod"
                                    defaultSelectedOption={{ value: searchFilters["comparePeriod"]["name"], label: "" }}
                                    options={ReportingConstants.comparePeriod.filter((singleItem) => singleItem["status"] === "active")}
                                    setFieldValue={handler}
                                />
                            </div>
                            {searchFilters["comparePeriod"]["name"] === "userDefinedPeriod" ? <div className="col-sm-9">
                                <UserDefinedPeriod callback={(name, value) => { handler("comparePeriod", "userDefinedPeriod", value) }} />
                            </div> : ""
                            }


                        </div>
                    </div>

                    <div className="col-sm-12">
                        <CustomButton
                            buttonClassName="btn btn-sm btn-primary "
                            buttonStyle={{ marginTop: "10px", float: "right" }}
                            buttonOnClick={() => { callback("generateReport", searchFilters) }}
                            buttonText="Search"
                            isActive={allowSubmission}
                        />

                        <CustomButton
                            buttonClassName="btn btn-sm btn-secondary "
                            buttonStyle={{ marginTop: "10px", float: "right", marginRight: "20px" }}
                            buttonOnClick={() => { handler("clearFilters", "") }}
                            buttonText="Clear Filters"
                            isActive={true}
                        />

                        <CustomButton
                            buttonClassName="btn btn-sm btn-secondary "
                            buttonStyle={{ marginTop: "10px", float: "right", marginRight: "20px" }}
                            buttonOnClick={() => { callback("createBookmark", searchFilters) }}
                            buttonText="Save Bookmark"
                            isActive={allowSubmission}
                        />
                    </div>



                </div>
            </div>
        </div>
    </>)
}

export default SearchBox;