
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setTheme } from '../redux/themeSlice';  // Action creator

const LoadTheme = () => {
    const dispatch = useDispatch();

    useEffect(() => {
      const hostname = window.location.hostname;
      const subdomain = hostname.split('.')[0];
      

      // Based on the subdomain, dispatch the corresponding theme
      if (subdomain === 'resi') { 
        dispatch(setTheme({
          primaryColor: '#C4D600',
          cardPrimaryColor: '#C4D600',
          secondaryColor: '#2ecc71',
          backgroundColor: '#ecf0f1',
          textColor: '#2c3e50',
          logo: '/media/logos/resi-logo.png',
          loginLogo: '/media/logos/resi-logo-black.png',
          logoHeight: '55px',
          loginLogoHeight: '100px',
          customBtnBackgroundColor: '#C4D600',
        }));
        document.documentElement.style.setProperty('--primary-color',  '#C4D600'); // Default black
        document.documentElement.style.setProperty('--card-primary-color',  '#C4D600'); // Default black
        document.documentElement.style.setProperty('--custom-btn-color',  '#FFFFFF'); // Default black
        document.documentElement.style.setProperty('--custom-btn-background-color', '#C4D600'); // Default orange  
      }else if(subdomain === 'brass') {
        dispatch(setTheme({
          primaryColor: '#000000	',
          cardPrimaryColor: '#C4D600',
          secondaryColor: '#2ecc71',
          backgroundColor: '#ecf0f1',
          textColor: '#2c3e50',
          logo: null,
          loginLogo: '/media/logos/brass-logo1.png',
          logoHeight: '55px',
          loginLogoHeight: '100px',
          customBtnBackgroundColor: '#000000',
          customBtnTextHoverColor : '#FFFFFF',
        }));
        document.documentElement.style.setProperty('--primary-color',  '#000000	'); // Default black
        document.documentElement.style.setProperty('--card-primary-color',  '#FFFFFF'); // Default black
        document.documentElement.style.setProperty('--custom-btn-color',  '#FFFFFF'); // Default black
        document.documentElement.style.setProperty('--custom-btn-background-color', '#000000'); // Default orange  
        document.documentElement.style.setProperty('--custom-button-hover-color', '#ffffff'); // Default orange  
      } else {
        dispatch(setTheme({
          primaryColor: '#F08224',
          cardPrimaryColor: '#ffa500',
          secondaryColor: '#8e44ad',
          backgroundColor: '#f7f9f9',
          textColor: '#34495e',
          logo: '/media/logos/brokaub-logo-horizontal.svg',
          loginLogo: '/media/logos/brokahub-logo-horizontal-black.svg',
          logoHeight: '35px',
          loginLogoHeight: '75px',
        }));
        //NO NEED TO UPDATE THE COLORS AS DEFAULTS ARE ALREADY SET
      }
    }, [dispatch]);
}

export default LoadTheme;