//LIST COMPANY USERS
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useEntityId, useToken } from '../../hooks/useAuth';
import { EditButton, AddButton, CustomButton } from '../HtmlComponents';
import RightDrawer from "../HtmlComponents/modals/RightDrawer";
import { getCompanyUsers } from "../../api/";
import { replaceNullWithString, formatDate, dateFilter } from '../../helpers';

import AddUser from "../users/AddUser";
import UpdateUser from "../users/UpdateUser";
import ListUsers from "../users/ListUsers";
import Notifications from "../global/Notifications";

const ListCompanyUsers = () => {
    const [companyUsers, setCompanyUsers] = useState([]);
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: { width: "800" } });

    const companyId = useEntityId();
    const token = useToken();

    useEffect(() => {
        fetchCompanyUsers();
    }, []);


    const fetchCompanyUsers = async () => {
        const companyUsersR = await getCompanyUsers(companyId, token);
        console.log("CMPANY USERS: ", companyUsersR);
        console.log(companyUsersR)
        setCompanyUsers(companyUsersR);
    }

    const payloadUpdateHandler = (action, values, meta = {}) =>{
        try{
            switch(action){
               /* case "addUser":
                    var users = singleCreditRep.users;
                     if(!Array.isArray(users)){users = [];}
                     users.push(values);                     
                    setSingleCreditRep({...singleCreditRep, users: users });  //UPDAET THE STATE    
                    drawHandler("closeDrawer");  //CLOSE RIGHT DRAW
                    break;    */                
                case "updateUser":
                    var users = companyUsers;                     
                    var newUsers = users.map(user => {
                        if (user.id === values.id) { return values; } // Replace with the new user values if IDs match
                        else { return user;  } // Keep the existing user otherwise
                    });
                    setCompanyUsers(newUsers); //UPDAET THE STATE 
                    drawHandler("closeDrawer");  //CLOSE RIGHT DRAW
                    break;
                    /*
                case "deleteUser":
                    var users = singleCreditRep.users;
                    var newUsers = [];
                    users.map((user) => { if (user.id !== values.id) {  newUsers.push(user); } })
                    setSingleCreditRep({...singleCreditRep, users: newUsers }); //UPDAET THE STATE
                    break;    */                
            }
        }
        catch(e){
            console.log(e.message);
        }

    }

    const drawHandler = (actionName, meta = {}) => {
        switch (actionName) {
            case "openDraw":
                setDrawerSettings({ ...drawerSettings, visible: true, 
                    childName: meta?.childName || "", 
                    heading: meta?.heading || "",
                    meta: { width: "800", userId : meta.userId ? meta.userId : 0 }
                 })
                break;
            case "closeDrawer":
                setDrawerSettings({ ...drawerSettings, visible: false })
                break;               
        }
    }
 

    const UsersListing = () => {
        return (<>
            <div className="px-9 pt-3 card-rounded  w-100 bg-primary pb-3" style={{ "marginTop": "30px" }}>
                <div className="d-flex flex-stack">
                    <h3 className="m-0 text-white fw-bolder fs-3">Company Users(Super-admin, finance, auditor)</h3>
                    {/*
                        <CustomButton
                            buttonClassName="btn btn-sm btn-light btn-active-secondary"
                            isActive={true}
                            buttonText="Add"
                            buttonOnClick={() => { drawHandler("addUser") }}
                        />
                    */}
                </div>
            </div>
            <div className="bg-body shadow-sm card-rounded mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "20px" }}>
                <ListUsers users={companyUsers && Array.isArray(companyUsers) && companyUsers.length> 0 ? companyUsers : []}  callback = { (action, id) => {
                    console.log("USER ID from line 102 list company users -- : ", id);
                    switch (action) {
                        case "updateUser":
                            console.log("UPDATE USER: ", id);
                            drawHandler("openDraw" , {"childName" : "updateUser", heading: "Update User", userId : id})
                            break;
                        case "refreshUsersListing":
                            Notifications("error", "Company User can not be deleted");
                            //payloadUpdateHandler("deleteUser", {id: id})
                            break;
                    }
                }} />
            </div>
        </>)
    }

 



    return (
        <>
            <div id="kt_content_container" className="container-xxl">
                <div className="row g-5 g-xl-8">
                    <div className="col-xl-12">
                        <UsersListing />
                    </div>
                </div>
            </div>
            <div className="engage-toolbar d-flex position-fixed px-5 fw-bold zindex-2 top-50 end-0 transform-90 mt-5 mt-lg-20 gap-2" style={{ zIndex: "1" }}>
                <CustomButton buttonClassName="btn  bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0" buttonStyle={{ minWidth: "100px" }} isActive={true} buttonOnClick={() => { drawHandler("openFilesDraw") }} buttonText="Files" />
            </div>

            {drawerSettings["visible"] ?
                <RightDrawer heading={drawerSettings["heading"]} width={drawerSettings["meta"]["width"] !== undefined ? drawerSettings["meta"]["width"] : "500"} callback={drawHandler}>
                    {
                        drawerSettings["childName"] == "AddUser" ? <AddUser entityType="company" entityId={companyId} callback= { (data) => { payloadUpdateHandler("addUser" , data ) }}  /> : ""
                    }
                    {
                        drawerSettings["childName"] == "updateUser" ? <UpdateUser entityType="company" entityId={companyId} userId = {drawerSettings["meta"]["userId"]} callback= { (data) => { payloadUpdateHandler("updateUser" , data ) }}  /> : ""
                    }
                </RightDrawer> : ""
            }

        </>
    );

}


export default ListCompanyUsers;