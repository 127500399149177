import {getCreditRep , createCreditRep , updateCreditRep , getCreditRepList , createCreditRepRCTI} from "../api/creditRepApi";
import {getUser, addUser, updateUser, updatePassword , forgotPassword, resetPassword,  getUsers, deleteUser} from '../api/UserApi';
import{addReferrerGroupManager, updateReferrerGroupManager, getReferrerGroupManager, getReferrerGroupManagers , deleteReferrerGroupManager} from '../api/referrerGroupManagerApi';
import {getReferrerGroupsList, getReferrerGroupsListAgainstCreditRep, getReferrerGroup, createReferrerGroup, updateReferrerGroup, daleteReferrerGroup, createReferrerGroupRCTI, createReferrerGroupCommissionSummary, listAllReferrerGroupInvocies, submitReferrerGroupInvociesToXero , getParentReferrerGroupCommission} from "../api/referrerGroupApi";
import {createConsumerApplication , updateApplication,  getApplication , getApplicationMatch, getSignleApplicationOverview,  getApplicationsList, performApplicationAdditionalActions ,getApplicationProgressStatus
, applicationsNotAssignedToAnalystAfterCertainTime, 
createApplicant , updateApplicant , getApplicationReferrerDetails } from "../api/applicationApi";
import {getFolders} from "./FolderApi";
import {getFiles, updateFile ,uploadFile} from "./FileApi"; 
import {getGeneralFiles,  uploadGeneralFile , updateGeneralFile, deleteGeneralFile} from "./generalFileApi";
import {getGeneralFolders } from "./generalFolderApi";
import {createComment, getComments , deleteComment} from './commentApi';
import {createNote, getNotes , deleteNote} from './noteApi';
import {createInsuranceCompany, updateInsuranceCompany, getInsuranceCompany, getInsuranceCompaniesList, deleteInsuranceCompany } from './insuranceCompanyApi';
import { getLender, getLendersList, updateLender, createLender, deleteLender, listAllLenderInvocies , submitInvociesToXero} from './lenderApi';
import { getReferrersList, getReferrersListAgainstReferrerGroup, getReferrer, createReferrer,  updateReferrer , deleteReferrer, createReferrerRCTI, createReferrerGiftCardReport , listAllReferrerInvocies, submitReferrerInvociesToXero} from './referrersApi';
import {getContact, createContact, updateContact, deleteContact} from '../api/contactApi';
import {getOfficeContact, getAllOfficeContacts, createOfficeContact, updateOfficeContact} from '../api/officeContactApi';
import {createSettlement, updateSettlement, getSettlement, getSettlementsList} from './settlementApi';
import {createDeclined, updateDeclined, getDeclined, getDeclinedList, getAllDueFollowUp , deleteDeclined} from './declinedApi';
import {getTemplates, createESignatureRequest} from './eSignatureApi';
import {getBankStatementLinks, getBankStatementLink , createBankStatementLink, updateBankStatementLink, sendBankStatementRequestToCustomer} from './bankStatementLinksApi';
import {createBulkEmailRequest } from './bulkEmailRequestApi';
import{getApplicationsCount , getApplicationsCountAgainstGivenProgressStatus , getApplicationsCountAgaintStatusAndAnalyst, getAnalystDetailedCommissionReport , getAnalystGrossIncomeReport , getAnalystApplicationsCountByProgressStatus , getAnalystInsuranceReport , getAnalystBudgetReport, 
    getSettlementRateAgainstReceivedApplications, 
    getTotalReferralsReporting , getTotalSettledReferralReporting , getTotalFinancedAmountReporting ,
     getAverageIncomePerDealAfterReferrerCommissionReporting , getAverageIncomePerDealBeforeReferrerCommissionReporting,  getBranchIncomeReporting , loadReporting} from './reportingApi';
import{getReportingBookmark, getReportingBookmarks, createReportingBookmark , deleteReportingBookmark} from './reportingBookmarkApi';
import {createSMS} from './smsApi';
import {createSmsLog , getSmsLogs} from './smsLogApi';
import {listAllInsuranceInvocies, submitInsuranceInvociesToXero} from './insuranceApi';
import {getStaffBudget, getStaffBudgetList, createStaffBudget, updateStaffBudget} from "./staffBudgetApi";
import {getCrmCsvFile, sendEmail} from "./crmApi";
import { createRegister , updateRegister ,  getRegister , getSingleRegister } from './registerApi';
import{createCpd , updateCpd , getSingleCpd , getCpd } from './cpdApi';
import{searchCarInfo , downloadPpsrPdf, liveCarPricing} from './iAppraiseApi';
import{getRctiAdditionalData  } from './rctiAdditionalDataApi';
import {createEmailTemplate , updateEmailTemplate, getEmailTemplate, getEmailTemplates } from './emailTemplateApi';
import { createApplicantAccessToken , getApplicantAccessTokensByApplicationId, deleteApplicantAccessTokens} from './applicantAccessTokenApi';

import{newReferrerSignupRequest , referrerAccreditationRequest} from './publicApi';
import {registerForRealTimeUpdates, createEventSource, registerListener, unregisterListener, closeEventSource, isClientConnected } from './eventSourceManager';
import { getEquifaxFees,  listEquifaxFees, createEquifaxFees, updateEquifaxFees } from "./equifaxFeesApi";

import {getCompanyUsers} from './companyApi';

export { 
    getCreditRep , createCreditRep , updateCreditRep , getCreditRepList, createCreditRepRCTI , 
    getUser, addUser, updateUser, updatePassword , forgotPassword, resetPassword,  getUsers, deleteUser, 
    addReferrerGroupManager, updateReferrerGroupManager, getReferrerGroupManager, getReferrerGroupManagers , deleteReferrerGroupManager,
    getReferrerGroupsList, getReferrerGroupsListAgainstCreditRep, getReferrerGroup, createReferrerGroup, updateReferrerGroup, daleteReferrerGroup, createReferrerGroupRCTI, createReferrerGroupCommissionSummary, listAllReferrerGroupInvocies,  submitReferrerGroupInvociesToXero, getParentReferrerGroupCommission, 
    createConsumerApplication , updateApplication ,  getApplication , getApplicationMatch,  getSignleApplicationOverview , getApplicationsList, performApplicationAdditionalActions,getApplicationProgressStatus,
    applicationsNotAssignedToAnalystAfterCertainTime, 
    createApplicant , updateApplicant, getApplicationReferrerDetails, 
    getFolders,
    getFiles, updateFile, uploadFile,
    getGeneralFiles,  uploadGeneralFile, updateGeneralFile, deleteGeneralFile, 
    getGeneralFolders,
    createComment, getComments , deleteComment,
    createNote, getNotes , deleteNote,
    createInsuranceCompany, updateInsuranceCompany , getInsuranceCompany, getInsuranceCompaniesList, deleteInsuranceCompany,
    getLender, getLendersList, updateLender, createLender,deleteLender,  listAllLenderInvocies, submitInvociesToXero, 
    getReferrersList, getReferrersListAgainstReferrerGroup, getReferrer, createReferrer,  updateReferrer,  deleteReferrer, createReferrerRCTI , createReferrerGiftCardReport, listAllReferrerInvocies,  submitReferrerInvociesToXero, 
    getContact, createContact, updateContact, deleteContact,
    getOfficeContact, getAllOfficeContacts, createOfficeContact, updateOfficeContact,
    createSettlement, updateSettlement, getSettlement, getSettlementsList,
    createDeclined, updateDeclined, getDeclined, getDeclinedList, getAllDueFollowUp, deleteDeclined , 
    getTemplates, createESignatureRequest,
    getBankStatementLinks, getBankStatementLink , createBankStatementLink, updateBankStatementLink, sendBankStatementRequestToCustomer,
    createBulkEmailRequest, 
    getApplicationsCount, getApplicationsCountAgainstGivenProgressStatus,  getApplicationsCountAgaintStatusAndAnalyst,  getAnalystDetailedCommissionReport,  getAnalystGrossIncomeReport, getAnalystApplicationsCountByProgressStatus, getAnalystInsuranceReport,  getAnalystBudgetReport,
    getSettlementRateAgainstReceivedApplications,
    getTotalReferralsReporting, getTotalSettledReferralReporting, getTotalFinancedAmountReporting, getAverageIncomePerDealAfterReferrerCommissionReporting,  getAverageIncomePerDealBeforeReferrerCommissionReporting , getBranchIncomeReporting, loadReporting, 
    getReportingBookmark, getReportingBookmarks, createReportingBookmark , deleteReportingBookmark,
    createSMS,
    createSmsLog, getSmsLogs,
    listAllInsuranceInvocies, submitInsuranceInvociesToXero, 
    getStaffBudget, getStaffBudgetList, createStaffBudget, updateStaffBudget,
    getCrmCsvFile, sendEmail, 
    createRegister , updateRegister , getRegister , getSingleRegister , 
    createCpd , updateCpd , getSingleCpd , getCpd ,
    searchCarInfo, downloadPpsrPdf, liveCarPricing, 
    getRctiAdditionalData,
    createEmailTemplate, updateEmailTemplate, getEmailTemplate, getEmailTemplates, 
    createApplicantAccessToken, getApplicantAccessTokensByApplicationId, deleteApplicantAccessTokens,
    newReferrerSignupRequest , referrerAccreditationRequest,
    registerForRealTimeUpdates, createEventSource, registerListener, unregisterListener, closeEventSource,  isClientConnected,
    getEquifaxFees,  listEquifaxFees, createEquifaxFees, updateEquifaxFees,
    getCompanyUsers
};