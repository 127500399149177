import axios from '../api/axios';

/*GET SINGLE CREDIT REP*/ 
const getCompanyUsers =  async (id , token) => {
  console.log("GETCREDITREP API ENDPOINT IS EXECUTED--------------------------------------->");
  const response = await axios.get(`companies/${id}/users`, { headers: { Authorization: "Bearer " + token } });
  return response.data ? response.data : {};
}



export {getCompanyUsers};