import { Link } from "react-router-dom";
import { formatDate, calculateTimeDifference, capitalizeFirstLowercaseRest } from '../../helpers';
import { CustomButton, IconButton } from '../HtmlComponents/';
import constants from '../../components/global/Constants';
import { useEntityType, useRole, useExtraInfo } from '../../hooks/useAuth';

const DeclinedListItem = ({ singleDeclined, callback }) => {
    const entityType = useEntityType();
    const role = useRole(); //GET THE ROLE OF LOGGED IN USER
    const extraInfo = useExtraInfo();

        //CHECK IF DELETE APP PERMISSON IS ALLOWED FOR ANALYST 
    const checkIfReinstatePermission = () => {
        if (role == "admin") { return true; }
        if (role == "analyst") {
            if (extraInfo?.additionalPermissions?.reInstateApplication == true) { return true; }
            else { return false; }
        }
    }


    const formatReasons = (reasons) => {
        var reasons = JSON.parse(reasons);
        var formatted_Reasons = "";
        //NOW WE HAVE CHANGED THE REAONS STRUCUTRE SO APPLICAITONS BEFORE AUGUST 2024, WE HAVE OLD STRUCUTRE BUT FOR NEW ONES WE HAVE NEW STRUCTURE

        //IDENTIFY THE STRUCTURE - V2
        if (reasons.ids) {
            //NEW STRUCTURE
            let declinedReasonsIds = reasons.ids;
            let otherData = reasons.otherData ? reasons.otherData : {};
            let otherReasonDescription = otherData.description ? otherData.description : "";

            formatted_Reasons = declinedReasonsIds.map(reasonId => {
                var label = constants.DECLINED_ID_TO_REASONS_MAPPING[reasonId] ? constants.DECLINED_ID_TO_REASONS_MAPPING[reasonId] : " ";
                return label === "other" ? label + ": " + otherReasonDescription +  " " : label+ " ";
                //return label + " ";
            })
        }
        else {
            //OLD STRUCTURE V1
            if (Array.isArray(reasons)) {
                formatted_Reasons = reasons.map(reason => {
                    var label = constants.DECLINED_REASONS[reason["type"]] ? constants.DECLINED_REASONS[reason["type"]] : " ";
                    var description = reason["description"] ? reason["description"] : "";
                    return reason["type"] == "other" ? label + ": " + description + " " : label + " ";
                })
            }
        }
        return formatted_Reasons;
    }

    //RETURN THE NUMBER OF COLUMNS BASED ON LOGGED IN USER ENTITY TYPE
    const TableRow = () => {
        if (["finance", "superAdmin", "admin"].includes(role)) {
            return (
                <>
                    <tr className="even text-start">
                        <td>{singleDeclined.applicationId}  </td>
                        <td>{singleDeclined.firstName}</td>
                        <td>{singleDeclined.surname}</td>
                        <td>{singleDeclined.amount}</td>
                        <td>{formatReasons(singleDeclined.reasons)}</td>
                        <td>{formatDate(singleDeclined.declinedDate, "unix", "DD/MM/YYYY")}</td>
                        <td>{formatDate(singleDeclined.followUpDate, "unix", "DD/MM/YYYY")}</td>
                        <td>{singleDeclined.analyst}</td>
                        <td>{singleDeclined.referrerGroup}  </td>
                        <td>{singleDeclined.referrer}  </td>
                        <td>{singleDeclined.referrerUserName}  </td>

                        <td style={{ minWidth: "90px" }}>
                            <IconButton iconType="addPerson" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Reinstate" buttonOnClick={() => { callback("reinstate", { declinedId: singleDeclined.id, applicationId: singleDeclined.applicationId }) }} />
                            <IconButton iconType="edit" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Edit" buttonOnClick={() => { window.location.href = `/user/editDeclined/${singleDeclined.id}`; }} />
                            <IconButton iconType="files" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Files" buttonOnClick={() => { window.location.href = `/user/applicationFiles/${singleDeclined.applicationId}`; }} />
                            <IconButton iconType="copy" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Copy" buttonOnClick={() => { callback("copyApplication", singleDeclined.applicationId) }} />
                            {/*<IconButton iconType="deleteBin" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "15px", color: "#b20f0f" }} tootltip={true} tooltipDescription="Delete" buttonOnClick={() => { callback("delete", singleDeclined.id) }} /> */}
                        </td>
                    </tr>
                </>
            )
        }
        else if (["analyst"].includes(role)) {
            return (
                <>
                    <tr className="even text-start">
                        <td>{singleDeclined.applicationId}  </td>
                        <td>{singleDeclined.firstName}</td>
                        <td>{singleDeclined.surname}</td>
                        <td>{singleDeclined.amount}</td>
                        <td>{formatReasons(singleDeclined.reasons)}</td>
                        <td>{formatDate(singleDeclined.declinedDate, "unix", "DD/MM/YYYY")}</td>
                        <td>{formatDate(singleDeclined.followUpDate, "unix", "DD/MM/YYYY")}</td>
                        <td>{singleDeclined.analyst}</td>
                        <td>{singleDeclined.referrerGroup}  </td>
                        <td>{singleDeclined.referrer}  </td>
                        <td>{singleDeclined.referrerUserName}  </td>
                        <td> { 
                            checkIfReinstatePermission() == true ? 
                            <IconButton iconType="addPerson" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Reinstate" buttonOnClick={() => { callback("reinstate", { declinedId: singleDeclined.id, applicationId: singleDeclined.applicationId }) }} />
                            : ""
                         }  </td>
                    </tr>
                </>
            )
        }
        else if (entityType == "referrerGroup") {
            return (
                <>
                    <tr className="even text-start">
                        <td>{singleDeclined.applicationId}  </td>
                        <td>{singleDeclined.referrer}  </td>
                        <td>{singleDeclined.firstName}</td>
                        <td>{singleDeclined.surname}</td>
                        <td>{singleDeclined.amount}</td>
                        <td>{formatReasons(singleDeclined.reasons)}</td>
                        <td> <span className="applicationStatus" onClick={() => { callback("viewComentsOnly", { "applicationId": singleDeclined.applicationId }) }}> Notes </span></td>
                        <td>{formatDate(singleDeclined.declinedDate, "unix", "DD/MM/YYYY")}</td>
                        <td>{singleDeclined.analyst}</td>
                        <td>{singleDeclined.referrer}  </td>
                        <td>{singleDeclined.referrerUserName}  </td>
                    </tr>
                </>
            )
        }
        else if (entityType == "referrer") {
            return (
                <>
                    <tr className="even text-start">
                        <td>{singleDeclined.applicationId}  </td>
                        <td>{singleDeclined.firstName}</td>
                        <td>{singleDeclined.surname}</td>
                        <td>{singleDeclined.amount}</td>
                        <td>{constants.LOAN_PURPOSES[singleDeclined.purpose]}</td>
                        <td>{formatReasons(singleDeclined.reasons)}</td>
                        <td> <span className="applicationStatus" onClick={() => { callback("viewComentsOnly", { "applicationId": singleDeclined.applicationId }) }}> Notes </span></td>
                        <td>{formatDate(singleDeclined.declinedDate, "unix", "DD/MM/YYYY")}</td>
                        <td>{singleDeclined.analyst}</td>
                    </tr>
                </>
            )

        }
        else {
            return (
                <>
                    <tr className="even text-start">
                        <td>{singleDeclined.applicationId}  </td>
                        <td>{singleDeclined.referrer}  </td>
                        <td>{singleDeclined.firstName}</td>
                        <td>{singleDeclined.surname}</td>
                        <td>{singleDeclined.amount}</td>
                        <td>{constants.LOAN_PURPOSES[singleDeclined.purpose]}</td>
                        <td>{formatReasons(singleDeclined.reasons)}</td>
                        <td> <span className="applicationStatus" onClick={() => { callback("viewComentsOnly", { "applicationId": singleDeclined.applicationId }) }}> Notes </span></td>
                        <td>{formatDate(singleDeclined.declinedDate, "unix", "DD/MM/YYYY")}</td>
                        <td>{singleDeclined.analyst}</td>
                    </tr>
                </>
            )
        }


    }


    /** editConsumerApplication => use path based on applicaiton type , FOR CONSUMER APPLICATIONS USE editConsumerApplication, OTHERWISE USE COMMERCIAL ONE */
    return (
        <>
            <TableRow />
        </>
    )
}

export default DeclinedListItem;