import React, { useState, useEffect } from 'react';
import { useToken, useEntityType, useEntityId, useRole } from '../../hooks/useAuth';
import { getReferrersList, deleteReferrer } from '../../api';
import ReferrerListItem from './ReferrerListItem';
import { CustomButton, InputField, ConfirmDialog, SelectField } from '../HtmlComponents';
import { Navigate, NavigationType, useNavigate } from 'react-router-dom'
import Notifications from '../../components/global/Notifications';

const ListReferrers = ({ status }) => {

    const [referrersList, setReferrersList] = useState([]);
    const [filteredReferrersList, setFilteredReferrersList] = useState([]);
    const [filter, setFilter] = useState("");
    const [searchOption, setSearchOption] = useState("referrerAndstaff");  //referrerOnly => search if name matched - local search form already laoded data


    const token = useToken();
    const entityId = useEntityId();
    const entityType = useEntityType();
    const role = useRole();

    let navigate = useNavigate();

    const fetchReferrersList = async () => {
        const response = await getReferrersList(token, status, "");
        setReferrersList(response["referrers"] !== undefined ? response["referrers"] : []);
        setFilteredReferrersList(response["referrers"] !== undefined ? response["referrers"] : []);
    }
    const filterReferrerList = async() => {
        if (filter.length == 0) {
            setFilteredReferrersList(referrersList);
        }
        else {
            var lowerCaseFilter = filter.toLowerCase();
            var tmpReferrerList = [];
            if (Array.isArray(referrersList) && searchOption === "referrerOnly") {
                referrersList.map(singleReferrer => {
                    var entityName = singleReferrer.entityName !== undefined && singleReferrer.entityName !== null && singleReferrer.entityName.length > 0 ? singleReferrer.entityName.toLowerCase() : "";
                    var tradingName = singleReferrer.tradingName !== undefined && singleReferrer.tradingName !== null && singleReferrer.tradingName.length > 0 ? singleReferrer.tradingName.toLowerCase() : "";
                    var regExpression = new RegExp('.*' + lowerCaseFilter + '*.');
                    if (regExpression.test(entityName) || regExpression.test(tradingName)) { tmpReferrerList.push(singleReferrer); }
                })
                setFilteredReferrersList(tmpReferrerList);
            }
            else if (searchOption === "referrerAndstaff") {
                var lowerCaseFilter = filter.toLowerCase();
                const response = await getReferrersList(token, status, lowerCaseFilter);
                setFilteredReferrersList(response["referrers"] !== undefined ? response["referrers"] : []);
            }
        }
    }

    useEffect(() => {
        fetchReferrersList();

        if(role === "finance"){ setSearchOption("referrerOnly")}
        else{setSearchOption("referrerAndstaff")}

    }, []);

    //FILTER OBSERVER
    useEffect(() => {
        if (filter !== null && (filter.length > 2 || filter.length == 0)) {
            filterReferrerList();
            //fetchReferrersList();
        }
    }, [filter, searchOption])

    const handler = async (name, value) => {
        switch (name) {
            case "archiveReferrer":
                ConfirmDialog({ callback: () => { handler("executeArchiveReferrer", value) }, "purpose": "general", "heading": `Are you sure you want to archive it?`, "description": "" })
                break;
            case "executeArchiveReferrer":
                var response = await deleteReferrer(token, value);
                console.log(response);
                if (response["error"] != undefined && response["error"] == 1) {
                    Notifications("error", response["message"] || "Some error occured.")
                }
                else {
                    Notifications("success", response["message"] || "Deleted Successfully.");
                    fetchReferrersList();
                }
                break;
        }
    }

    const CreateReferrerButtonHTML = () => {
        if ( (entityType == "creditRep" && role == "admin") || ["superAdmin"].includes(role) ) {
            return (
                <>
                    <div className='row'>
                        <div className='cols-m-12'>
                            <CustomButton buttonClassName="btn-primary btn-sm" isActive={true} buttonStyle={{ float: "right", marginBottom: "10px" }} buttonOnClick={() => { navigate("/user/createReferrer") }} buttonText="Create" />
                        </div>
                    </div>
                </>
            )
        }
        else {
            return "";
        }
    }

    return (
        <>
            <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
                <CreateReferrerButtonHTML />

                <div className='row mb-5'>
                    <div className='col-sm-3'>
                        <InputField
                            placeholder="Lookup"
                            setFieldValue={(name, value) => { setFilter(value); }}
                        />
                    </div>
                    <div className='col-sm-3'>
                        <SelectField
                            fieldName="searchOptions"
                            options={[{ value: "referrerOnly", label: "Referrer Only" }, { value: "referrerAndstaff", label: "Referrer & Staff" }]}
                            defaultSelectedOption={{ value: searchOption, label: "" }}
                            setFieldValue={() => { }}
                            callback={(name, value) => { setSearchOption(value); }}

                        />

                    </div>
                </div>
                <div className="card" data-select2-id="select2-data-132-dpd0">
                    <div className="card-body pt-0">
                        <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="table-responsive">
                                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                                    <thead>
                                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1">Trading Name</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1">Entity Name</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1">Structure</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1">Email</th>
                                            {status == "active" ? <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1">Actions</th> : ""}
                                        </tr>
                                    </thead>
                                    <tbody className="fw-bold">
                                        {
                                            referrersList && Array.isArray(filteredReferrersList) ?
                                                filteredReferrersList.map(singleReferrer => (
                                                    <ReferrerListItem key={singleReferrer.id} item={singleReferrer} callback={handler} status={status} />
                                                )) : <tr></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListReferrers;