module.exports = {

    "reportForOptions": [
        { value: "allNFAL", label: "All NFAL", status: "active", authorizedRole: ["superAdmin", "admin"], authorizedEntity: ["company", "creditRep"] },
        { value: "referrerGroup", label: "Referrer Group", status: "active", authorizedRole: ["superAdmin" , "user" , "referrerGroupManager"], authorizedEntity: ["company", "creditRep",  "referrerGroup"] },
        { value: "referrer", label: "Referrer", status: "active", authorizedRole: ["superAdmin", "user", "referrerGroupManager"], authorizedEntity: ["company", "creditRep","referrerGroup"] },
        { value: "individualReferrer", label: "Individual Referrer/Staff", status: "active", authorizedRole: ["superAdmin", "user", "referrerGroupManager"], authorizedEntity: ["company", "creditRep","referrerGroup"] },
        { value: "allBrokersCombinedListed", label: "All Brokers Combined & Listed", status: "active", authorizedRole: ["superAdmin", "admin", "analyst"], authorizedEntity: ["company", "creditRep"] },
        { value: "creditReps", label: "Credit Reps", status: "active", authorizedRole: ["superAdmin"], authorizedEntity: ["company"] },
        { value: "state", label: "State (Vic, NSW Etc.)", status: "active", authorizedRole: ["superAdmin", "user", "referrerGroupManager"], authorizedEntity: ["company", "creditRep","referrerGroup"] }
    ],


    "period": [
        { value: "currentDay", label: "Current day", status: "active" },
        { value: "currentWeekToDate", label: "Current Week to date", status: "active" },
        { value: "currentMonthToDate", label: "Current Month to date", status: "active" },
        { value: "previousMonth", label: "Previous Month", status: "active" },
        { value: "lastQuarter", label: "Last Quarter", status: "active" },
        { value: "lastSixMonth", label: "Last Six Month", status: "active" },
        { value: "lastTwelveMonths", label: "Last Twelve Months", status: "active" },
        { value: "currentYearToDate", label: "Current year to date", status: "active" },
        { value: "userDefinedPeriod", label: "User Defined Period", status: "active" }
    ],

    "periodPeriodicType": {
        "currentDay": "daily",
        "currentWeekToDate": "daily",
        "currentMonthToDate": "daily",
        "previousMonth": "daily",
        "lastQuarter": "monthly",
        "lastSixMonth": "monthly",
        "lastTwelveMonths": "monthly",
        "currentYearToDate": "monthly",
        "userDefinedPeriod": "monthly",
    },

    "comparePeriod": [
        { value: "none", label: "None", status: "active" },
        { value: "previousMonth", label: "Previous Month", status: "active" },
        { value: "sameMonthLastYear", label: "Same(Current) Month last year", status: "active" },
        { value: "samePeriodLastYear", label: "Same Period Last year", status: "active" },
        { value: "monthByMonth", label: "Month by Month", status: "inActive" },
        { value: "userDefinedPeriod", label: "User Defined Period", status: "active" }
    ],

    stats: [
        {
            value: "totalReferralsReceivedAscendingOrderBasedOnNumbers",
            label: "Total Referrals Received", 
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: ["referrerGroup", "referrer", "individualReferrer",  "state"],
                referrerGroupManager: ["referrerGroup", "referrer", "individualReferrer",  "state"]
            }
        },
        {
            value: "currentApprovedReferralsAccurateBasedOnReceivedDatePercentage",
            label: "Current Approved Referrals Accurate  based on received date %",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: ["referrerGroup", "referrer", "individualReferrer",  "state"],
                referrerGroupManager: ["referrerGroup", "referrer", "individualReferrer",  "state"]
            }
        },
        {
            value: "currentSettledReferralsAcurateBasedOnReceivedDatePercentage",
            label: "Current Settled referrals Acurate  based on received date %",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: ["referrerGroup", "referrer", "individualReferrer",  "state"],
                referrerGroupManager: ["referrerGroup", "referrer", "individualReferrer",  "state"]
            }
        },
        {
            value: "currentDeclinedReferralsAccurateBasedOnReceivedDatePercentage",
            label: "Current Declined Referrals Accurate based on received date %",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: ["referrerGroup", "referrer", "individualReferrer",  "state"],
                referrerGroupManager: ["referrerGroup", "referrer", "individualReferrer",  "state"]
            }
        },
        {
            value: "currentApprovedReferrals",
            label: "Current Approved Referrals",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: ["referrerGroup", "referrer", "individualReferrer",  "state"],
                referrerGroupManager: ["referrerGroup", "referrer", "individualReferrer",  "state"]
            }
        },
        {
            value: "currentSettledReferrals",
            label: "Current Settled referrals",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: ["referrerGroup", "referrer", "individualReferrer",  "state"],
                referrerGroupManager: ["referrerGroup", "referrer", "individualReferrer",  "state"]
            }
        },
        {
            value: "currentDeclinedReferrals",
            label: "Current Declined Referrals",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: ["referrerGroup", "referrer", "individualReferrer",  "state"],
                referrerGroupManager: ["referrerGroup", "referrer", "individualReferrer",  "state"]
            }
        },
        {
            value: "activeReferrals",
            label: "Active Referrals",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: ["referrerGroup", "referrer", "individualReferrer",  "state"],
                referrerGroupManager: ["referrerGroup", "referrer", "individualReferrer",  "state"]
            }
        },
        {
            value: "settlementRateApplicationsApprovedToSettlementPercentage",
            label: "Settlement Rate - Applications Approved to Settlement %",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: ["referrerGroup", "referrer", "individualReferrer",  "state"],
                referrerGroupManager: ["referrerGroup", "referrer", "individualReferrer",  "state"]
            }
        },
        {
            value: "didNotProceedApplicationsPercentage",
            label: "Did not proceed - applications received to did not proceed %",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: ["referrerGroup", "referrer", "individualReferrer",  "state"],
                referrerGroupManager: ["referrerGroup", "referrer", "individualReferrer",  "state"]
            }
        },
        {
            value: "statsForAllIndividualReasonsForDeclinedPercentage",
            label: "Stats for all individual reasons for declined",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: ["referrerGroup", "referrer", "individualReferrer",  "state"],
                referrerGroupManager: ["referrerGroup", "referrer", "individualReferrer",  "state"]
            }
        },
        {
            value: "daysToDecision",
            label: "Days to decision i.e. approved or unable to assist From received date",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: ["referrerGroup", "referrer", "individualReferrer",  "state"],
                referrerGroupManager: ["referrerGroup", "referrer", "individualReferrer",  "state"]
            }
        },
        {
            value: "naf",
            label: "NAF",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: ["referrerGroup", "referrer", "individualReferrer",  "state"],
                referrerGroupManager: ["referrerGroup", "referrer", "individualReferrer",  "state"]
            }
        },
        {
            value: "averageNAF",
            label: "Average NAF",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: ["referrerGroup", "referrer", "individualReferrer",  "state"],
                referrerGroupManager: ["referrerGroup", "referrer", "individualReferrer",  "state"]
            }
        },
        {
            value: "averageGrossIncomePerSettlement",
            label: "Average Gross Income Per settlement",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["referrerGroup", "referrer", "individualReferrer", "creditReps", "state"],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: ["referrerGroup", "referrer", "individualReferrer",  "state"],
                referrerGroupManager: ["referrerGroup", "referrer", "individualReferrer",  "state"]
            }
        },
        {
            value: "grossIncome",
            label: "Gross Income",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: ["referrerGroup", "referrer", "individualReferrer",  "state"],
                referrerGroupManager: ["referrerGroup", "referrer", "individualReferrer",  "state"]
            }
        },
        {
            value: "nettIncome",
            label: "Nett Income",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["referrerGroup", "referrer", "individualReferrer","creditReps", "state"],
                analyst: [""],
                referrerGroupUser: ["referrerGroup", "referrer", "individualReferrer",  "state"],
                referrerGroupManager: ["referrerGroup", "referrer", "individualReferrer",  "state"]
            }
        },
        {
            value: "averageNettIncomePerSettlement",
            label: "Average Nett Income per settlement",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["referrerGroup", "referrer", "individualReferrer", "creditReps", "state"],
                analyst: [""],
                referrerGroupUser: ["referrerGroup", "referrer", "individualReferrer",  "state"],
                referrerGroupManager: ["referrerGroup", "referrer", "individualReferrer",  "state"]
            }
        },
        {
            value: "dealerCommission",
            label: "Dealer Commission",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["referrerGroup", "referrer", "individualReferrer", "creditReps", "state"],
                analyst: [""],
                referrerGroupUser: ["referrerGroup", "referrer", "individualReferrer",  "state"],
                referrerGroupManager: ["referrerGroup", "referrer", "individualReferrer",  "state"]
            }
        },
        {
            value: "averageDealerCommissionPerDeal",
            label: "Average Dealer Comms per deal",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["referrerGroup", "referrer", "individualReferrer", "creditReps", "state"],
                analyst: [""],
                referrerGroupUser: [],
                referrerGroupManager: []
            }
        },
        {
            value: "dealerCommissionAsPercentageOfGrossIncome",
            label: "Dealer comms as percentage of gross income",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["referrerGroup", "referrer", "individualReferrer", "creditReps", "state"],
                analyst: [""],
                referrerGroupUser: [],
                referrerGroupManager: []
            }
        },
        {
            value: "insuranceIncomeTotal",
            label: "Insurance Income Total",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: [],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: [],
                referrerGroupManager: []
            }
        },
        {
            value: "insuranceIncomeComprehensive",
            label: "Insurance Income Motor | Comprehensive",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: [],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: [],
                referrerGroupManager: []
            }
        },
        {
            value: "insuranceIncomeWarranty", 
            label: "Insurance Income Warranty", 
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: [],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: [],
                referrerGroupManager: []
            }
        },
        {
            value: "insuranceIncomeOther",
            label: "Insurance Income Other",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: [],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: [],
                referrerGroupManager: []
            }
        },
        {
            value: "commercialLoansPercentage",
            label: "Commercial Loans %",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: [],
                referrerGroupManager: []
            }
        },
        {
            value: "consumerLoansPercentage",
            label: "Consumer Loans %",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: [],
                referrerGroupManager: []
            }
        },
        {
            value: "individualLoanPurpose",
            label: "Individual Loan Purpose",
            status: "active",
            authorized: {
                superAdmin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                admin: ["allNFAL", "referrerGroup", "referrer", "individualReferrer", "allBrokersCombinedListed", "creditReps", "state"],
                analyst: ["allBrokersCombinedListed"],
                referrerGroupUser: [],
                referrerGroupManager: []
            }
        },
    ],

    STATES: [
        { value: "vic", label: "Victoria" },
        { value: "nsw", label: "New South Wales" },
        { value: "sa", label: "South Australia" },
        { value: "qld", label: "Queensland" },
        { value: "act", label: "Australian Capital Territory" },
        { value: "tas", label: "Tasmania" },
        { value: "nt", label: "Northern Territory" },
        { value: "wa", label: "Western Australia" }
    ],



    //DATA AGAINST EACH STAT - ONLY LOADED FOR THOSE WHERE DATA IS REQUIRED
    STAT_OPTIONS: {
        statsForAllIndividualReasonsForDeclinedPercentage: [
            {
                "value": "adverseCreditFile",
                "label": "Adverse Credit File",
                "children": []
            },
            {
                "value": "poorLoanHistory",
                "label": "Poor loan history",
                "children": []
            },
            {
                "value": "applicationInaccuracies",
                "label": "Application Inaccuracies",
                "children": []
            },
            {
                "value": "bankAccountConduct",
                "label": "Bank account conduct",
                "children": [
                    { "value": "dishonours", "label": "Dishonours", "children": [] },
                    { "value": "daysOverdrawn", "label": "Days overdrawn", "children": [] },
                    { "value": "gambling", "label": "Gambling", "children": [] },
                    { "value": "undisclosedLiabilities", "label": "Undisclosed Liabilities", "children": [] },
                    { "value": "highRiskCashTransfers", "label": "High Risk cash transfers", "children": [] }
                ]
            },
            {
                "value": "capacity",
                "label": "Capacity",
                "children": []
            },
            {
                "value": "employmentStatus",
                "label": "Employment Status",
                "children": [
                    { "value": "casual", "label": "Casual", "children": [] },
                    { "value": "tooShortTerm", "label": "Too short term", "children": [] },
                    { "value": "newABN", "label": "New ABN", "children": [] },
                    { "value": "tooManyJobsInShortTerm", "label": "Too many jobs in short term", "children": [] },
                    { "value": "seasonalWork", "label": "Seasonal Work", "children": [] },
                    { "value": "inconclusivePayslips", "label": "Inconclusive payslips", "children": [] }
                ]
            },
            {
                "value": "residencyStatus",
                "label": "Residency Status",
                "children": [
                    { "value": "visaNotAcceptable", "label": "Visa not acceptable", "children": [] },
                    { "value": "shortTermVisaLeft", "label": "Short term visa left", "children": [] },
                    { "value": "newToCountryCitizen", "label": "New to country Citizen", "children": [] },
                    { "value": "newPR", "label": "New PR", "children": [] }
                ]
            },
            {
                "value": "assetUnacceptable",
                "label": "Asset Unacceptable",
                "children": [
                    { "value": "lvr", "label": "LVR", "children": [] },
                    { "value": "minusEquity", "label": "Minus Equity", "children": [] }
                ]
            },
            {
                "value": "withdrawnByCustomer",
                "label": "Withdrawn by Customer",
                "children": [
                    { "value": "rateUnacceptable", "label": "Rate unacceptable", "children": [] },
                    { "value": "lossToAnotherProvider", "label": "Loss to another provider", "children": [] },
                    { "value": "notHappyWithService", "label": "Not happy with service", "children": [] },
                    { "value": "payingCash-purchasingOutright", "label": "Paying cash/Purchasing Outright", "children": [] },
                    { "value": "notBuyingThatAsset", "label": "Not buying that asset", "children": [] },
                    { "value": "doesNotWantToProceedAnymore", "label": "Does not want to proceed anymore", "children": [] },
                    { "value": "changeInCircumstances", "label": "Change in circumstances", "children": [] }
                ]
            },
            {
                "value": "withdrawnByReferrer",
                "label": "Withdrawn by Referrer",
                "children": [
                    { "value": "dealerCanGetApproved", "label": "Dealer can get approved", "children": [] },
                    { "value": "lackOfNFALService", "label": "Lack of NFAL service", "children": [] }
                ]
            },
            {
                "value": "customerNotResponding",
                "label": "Customer Not Responding",
                "children": [
                    { "value": "secondAttemptContactBySecondary", "label": "Second attempt at contact by secondary", "children": [] }
                ]
            },
            {
                "value": "other",
                "label": "Other",
                "children": []
            }
        ],   
        //NEED THIS FOR PIE CHART TO LIST ALL REASONS FOR DECLINED - OTHERWISE I WOULD NEED TO TRAVERSE THE REASONS AND THEN ADD THEM TO THE ARRAY , SO JUST PUT THEM HERE ALREADY IN REQUIRED FORMAT 
        statsForAllIndividualReasonsForDeclinedPercentageParentAndChildrenInOneDArray: [
            { "value": "adverseCreditFile", "label": "Adverse Credit File" },
            { "value": "poorLoanHistory", "label": "Poor loan history" },
            { "value": "applicationInaccuracies", "label": "Application Inaccuracies" },
            { "value": "bankAccountConduct", "label": "Bank account conduct" },
            { "value": "dishonours", "label": "Dishonours" },
            { "value": "daysOverdrawn", "label": "Days overdrawn" },
            { "value": "gambling", "label": "Gambling" },
            { "value": "undisclosedLiabilities", "label": "Undisclosed Liabilities" },
            { "value": "highRiskCashTransfers", "label": "High Risk cash transfers" },
            { "value": "capacity", "label": "Capacity" },
            { "value": "employmentStatus", "label": "Employment Status" },
            { "value": "casual", "label": "Casual" },
            { "value": "tooShortTerm", "label": "Too short term" },
            { "value": "newABN", "label": "New ABN" },
            { "value": "tooManyJobsInShortTerm", "label": "Too many jobs in short term" },
            { "value": "seasonalWork", "label": "Seasonal Work" },
            { "value": "inconclusivePayslips", "label": "Inconclusive payslips" },
            { "value": "residencyStatus", "label": "Residency Status" },
            { "value": "visaNotAcceptable", "label": "Visa not acceptable" },
            { "value": "shortTermVisaLeft", "label": "Short term visa left" },
            { "value": "newToCountryCitizen", "label": "New to country Citizen" },
            { "value": "newPR", "label": "New PR" },
            { "value": "assetUnacceptable", "label": "Asset Unacceptable" },
            { "value": "lvr", "label": "LVR" },
            { "value": "minusEquity", "label": "Minus Equity" },
            { "value": "withdrawnByCustomer", "label": "Withdrawn by Customer" },
            { "value": "rateUnacceptable", "label": "Rate unacceptable" },
            { "value": "lossToAnotherProvider", "label": "Loss to another provider" },
            { "value": "notHappyWithService", "label": "Not happy with service" },
            { "value": "payingCash-purchasingOutright", "label": "Paying cash/Purchasing Outright" },
            { "value": "notBuyingThatAsset", "label": "Not buying that asset" },
            { "value": "doesNotWantToProceedAnymore", "label": "Does not want to proceed anymore" },
            { "value": "changeInCircumstances", "label": "Change in circumstances" },
            { "value": "withdrawnByReferrer", "label": "Withdrawn by Referrer" },
            { "value": "dealerCanGetApproved", "label": "Dealer can get approved" },
            { "value": "lackOfNFALService", "label": "Lack of NFAL service" },
            { "value": "customerNotResponding", "label": "Customer Not Responding" },
            { "value": "secondAttemptContactBySecondary", "label": "Second attempt at contact by secondary" },
            { "value": "other", "label": "Other" }
        ],
        


        /*statsForAllIndividualReasonsForDeclinedPercentage: [
            { value: "adverseCreditFile", label: "Adverse Credit File" },
            { value: "poorCurrentOrPreviousLoanHistory", label: "Poor / Slow Current or Previous Loan History" },
            { value: "bankAccountConduct", label: "Bank Account Conduct (Dishonours etc)" },
            { value: "capacity", label: "Capacity / Serviceability" },
            { value: "employmentStatus", label: "Employment Status" },
            { value: "residencyStatus", label: "Residency Status (Visa / PR etc)" },
            { value: "assetUnacceptable", label: "Asset Unacceptable" },
            { value: "referralWithdrawnByCustomer", label: "Referral Withdrawn by Customer" },
            { value: "referralWithdrawnByReferrer", label: "Referral Withdrawn by Referrer" },
            { value: "referralNotResponding", label: "Referral Not Responding / Referral Filed" },
            { value: "other", label: "Other" }
        ],*/
        individualLoanPurpose: [
            { value: "carDealership", label: "Car - Dealership" },
            { value: "carPrivate", label: "Car - Private" },
            { value: "caravanTrailer", label: "Caravan / Trailer" },
            { value: "lineOfCredit", label: "Line of Credit" },
            { value: "marine", label: "Marine" },
            { value: "motorcycle", label: "Motorcycle" },
            { value: "personalLoan", label: "Personal Loan" },
            { value: "insuranceOnly", label: "Insurance Only" },
            { value: "equipment", label: "Equipment loans" },
            { value: "farmMachinery", label: "Farm Machinery" },
            { value: "earthMovingHaulage", label: "Earth Moving Haulage" },
            { value: "truck", label: "Truck" },
            { value: "goKart", label: "Go Kart" },
            { value: "debtConsolidation", label: "Debt Consolidation" },
            { value: "refinance", label: "Refinance" },
            { value: "plantandEquipment", label: "Plant and Equipment" },
            { value: "other", label: "Other" }
        ]

    },
    //ADD STATS THAT SUPPORT PIE CHART
    PIE_CHART_SUPPORTED_STATS: ["statsForAllIndividualReasonsForDeclinedPercentage", "individualLoanPurpose"]

}